import { Ref, UnwrapRef, ref, watch } from 'vue';

import { deepClone } from '@/clone';

export type FormErrors<T> = {
  [K in keyof T]: string[];
} & {
  [key: string]: string[];
};

interface FormMethods<T> {
  form: Ref<UnwrapRef<T>>;
  errors: Ref<FormErrors<T>>;
  setFormErrors: (newErrors: Partial<FormErrors<T>>) => void;
  clearFormErrors: () => void;
  resetForm: () => void;
  clear: () => void;
}

interface ValueMap<T> {
  [key: string]: T;
}

function emptyObjectValues<T>(obj: T): T {
  const result: T = {} as T;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const valuesMapped: ValueMap<any> = {
        string: '',
        number: 0,
        boolean: false,
        object: null,
        array: [],
        function: null
      };

      const value = obj[key];
      const valueType = typeof value === 'function' ? 'function' : Array.isArray(value) ? 'array' : typeof value;
      result[key] = valuesMapped[valueType] || null;
    }
  }

  return result;
}

export const formErrors = ref();

export function useForm<T extends Record<string, unknown>>(values: T): FormMethods<T> {
  const initialValues = ref(deepClone(values));

  const errorsEntries = Object.keys(values).map((key: keyof T) => [key, []]);

  const fromEntries = ref(values);

  const form = ref<T>(values);

  const errors = ref(Object.fromEntries(errorsEntries));

  function setFormErrors(values: Partial<FormErrors<T>>, extras?: Partial<FormErrors<T>>) {
    Object.assign(errors.value, values, extras);
  }

  function clearFormErrors() {
    errors.value = Object.fromEntries(errorsEntries);
  }

  function resetForm() {
    form.value = deepClone(initialValues.value);
    clearFormErrors();
  }

  function clear() {
    form.value = emptyObjectValues(fromEntries.value);
  }

  watch(
    () => formErrors.value,
    (values) => setFormErrors(errors.value, values)
  );

  return {
    form,
    errors,
    clear,
    setFormErrors,
    clearFormErrors,
    resetForm
  };
}

type Obj = Record<string, unknown>;

export function useFormReactive<T extends Obj, K extends Obj>(props: K, hydrationCallback: (props: K) => T) {
  const formWrapper = useForm(hydrationCallback(props));

  watch(props, (props) => {
    // @ts-ignore
    formWrapper.form.value = hydrationCallback(props);
  });

  return formWrapper;
}

export default useForm;
