import { AxiosRequestConfig, CancelToken } from 'axios';

import { APIObject, APIParams, PaginatedAPIResponse, PaginatedData, convertToPaginatedData } from '@/models/Pagination';

import { constructQuery } from '@/helpers/api/queryConstructorHelper';

import axios from '@/axios';
import store from '@/store';

import { isAxiosError } from '../AxiosHelper';
import { Localized } from '../LocaleHelper';
import { isCompanyDomain, isManagementDomain, isServiceDomain } from '../SubdomainHelper';

interface ParsedContentDisposition {
  parameters?: {
    filename?: string;
    type?: string;
  };
}

function parseContentDisposition(header: string): ParsedContentDisposition {
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = header.match(regex);

  if (matches && matches[1]) {
    const filename = matches[1].replace(/['"]/g, '');
    const type = filename.split('.').pop(); // Assuming the type is the file extension

    return {
      parameters: {
        filename,
        type
      }
    };
  }

  return {};
}

export type ApiDataType = string | number | boolean | { [x: string]: ApiDataType } | undefined;

export async function indexPaginated<T extends APIObject>(url: string, key: keyof T, params?: APIParams): Promise<PaginatedData<T[keyof T][0]>> {
  const urlWithQuery = constructQuery(params, url);
  const response = await axios.get<PaginatedAPIResponse<T>>(urlWithQuery);

  return convertToPaginatedData(response.data, key);
}

export async function index<T extends APIObject>(url: string, key: keyof T, params?: Omit<APIParams, 'page' | 'pagination'>): Promise<T[keyof T]> {
  const urlWithQuery = constructQuery(params, url);
  const response = await axios.get<T>(urlWithQuery);

  return response.data[key];
}

export async function get<T>(url: string, key: keyof T): Promise<T[keyof T] | null> {
  const obj = await getRaw<T>(url);

  if (obj === null || obj === undefined) {
    return null;
  }
  return obj[key];
}

export async function getRaw<T>(url: string): Promise<T | null> {
  try {
    const response = await axios.get<T>(url);
    return response.data;
  } catch (error) {
    if (isAxiosError(error) && (error.response?.status === 404 || error.response?.status === 403)) {
      return null;
    } else {
      throw error;
    }
  }
}

export async function post<T>(url: string, key: keyof T, form: { [x: string]: ApiDataType } | FormData | null): Promise<T[keyof T]> {
  const response = await axios.post<T>(url, form);
  return response.data[key];
}

export async function patch<T>(url: string, key: keyof T, form: { [x: string]: ApiDataType } | FormData | null): Promise<T[keyof T]> {
  if (form instanceof FormData) {
    form.append('_method', 'patch');
    return post<T>(url, key, form);
  } else {
    return post<T>(url, key, { ...form, _method: 'patch' });
  }
}

export async function uploadFile<T>(url: string, key: keyof T, file: File | Blob) {
  const formData = new FormData();
  formData.append('up_file', file);
  formData.append('_method', 'patch');

  const resp = axios.post<T>(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  return (await resp).data[key];
}

export function downloadFilePOST(url: string, title: string, downloadName?: string, cancelToken?: CancelToken) {
  return downloadFile(url, title, downloadName, cancelToken, 'post');
}

export function downloadFileGET(url: string, title: string, downloadName?: string, cancelToken?: CancelToken) {
  return downloadFile(url, title, downloadName, cancelToken, 'get');
}

export async function downloadFile(url: string, title: string, downloadName?: string, cancelToken?: CancelToken, method: 'post' | 'get' = 'post') {
  const config: AxiosRequestConfig = {
    method,
    url,
    headers: {
      'Content-Type': 'application/octet-stream',
      'Access-Control-Allow-Origin': '*'
    },
    responseType: 'blob',
    cancelToken
  };

  const response = await axios(config);

  const header = response.headers['content-disposition'];
  const backendFilename = header ? parseContentDisposition(header).parameters?.filename : null;

  const a = document.createElement('a');
  const href = window.URL.createObjectURL(response.data);
  a.title = title;
  a.href = href;
  a.target = '_blank';
  a.download = backendFilename ?? downloadName ?? title;
  a.click();
}

export async function destroy(url: string): Promise<void> {
  await axios.delete(url);
}

//
export async function destroyWithResponse<T>(url: string, key?: keyof T): Promise<T[keyof T] | null> {
  const response = await axios.delete<T>(url);

  if (!response.data || !key) {
    return null;
  }
  return response.data[key];
}

// misc:
const insertCompanyId = (str: string, id: number | undefined): string => {
  return str.replace('{company}', id?.toString() ?? 'NULL');
};

const preparePath = (path: string) => {
  if (path === '') {
    return '';
  }
  if (!path.startsWith('/')) {
    path = '/' + path;
  }
  return path;
};

export const companyBaseUrl = (suffix = ''): string => insertCompanyId('/company/{company}', store.state.company.publicCompany?.id) + preparePath(suffix);

export const tenantBaseUrl = (suffix = ''): string => insertCompanyId('/tenant/{company}', store.state.company.publicCompany?.id) + preparePath(suffix);

export const serviceBaseUrl = (suffix = ''): string => insertCompanyId('/service/{company}', store.state.auth.user?.selectedRoleable?.roleable_id) + preparePath(suffix);

export const managementBaseUrl = (suffix = ''): string => insertCompanyId('/management/{company}', store.state.company.publicCompany?.id) + preparePath(suffix);

export const baseUrl = (suffix = ''): string => {
  if (isManagementDomain()) {
    return managementBaseUrl(suffix);
  } else if (isServiceDomain()) {
    return serviceBaseUrl(suffix);
  } else if (isCompanyDomain()) {
    return companyBaseUrl(suffix);
  } else {
    throw new Error('used BaseUrl with unknown subdomain/roleable state');
  }
};

export function domainBase(suffix = '') {
  if (isManagementDomain()) {
    return '/management' + preparePath(suffix);
  } else if (isServiceDomain()) {
    return '/service' + preparePath(suffix);
  } else if (isCompanyDomain()) {
    return '/company' + preparePath(suffix);
  } else {
    throw new Error('used BaseUrl with unknown subdomain/roleable state');
  }
}

// convert response to class
interface APIClassConstructor<T, ApiData> {
  new (params: ApiData): T;
}

export const convertTo =
  <T, Args>(Constructor: APIClassConstructor<T, Args>) =>
  (args: Args) => {
    return new Constructor(args);
  };

export const convertAllTo =
  <T, Args>(Constructor: APIClassConstructor<T, Args>) =>
  (args: Args[]) => {
    return args.map(convertTo(Constructor));
  };

export const convertWithNullTo =
  <T, Args>(Constructor: APIClassConstructor<T, Args>) =>
  (args: Args | null) => {
    if (!args) {
      return null;
    }
    return new Constructor(args);
  };

export const convertPaginatedTo =
  <T, Args>(Constructor: APIClassConstructor<T, Args>) =>
  (paginatedArgs: PaginatedData<Args>) => {
    const data = paginatedArgs.data.map(convertTo(Constructor));
    return { ...paginatedArgs, data };
  };

type FormTypeOptionPrimitive = number | boolean | string | undefined | null | Date | File | Blob;
type FormTypeOption = FormTypeOptionPrimitive | Localized<FormTypeOptionPrimitive>;
function isFormTypeOption(val: unknown): val is FormTypeOption {
  return !Array.isArray(val);
}

export type FormType = {
  [x: string]: FormTypeOption | FormType[] | FormTypeOption[];
};

function addToFormData(formData: FormData, key: string, val: unknown, includeAll: boolean) {
  if (val !== undefined && (includeAll || val !== '')) {
    if (val === null) {
      //value is null, add empty string so the backend knows to nullify this value
      formData.append(key, '');
    } else if (typeof val === 'number') {
      formData.append(key, val.toString());
    } else if (typeof val === 'boolean') {
      formData.append(key, val ? '1' : '0');
    } else if (val instanceof Date) {
      formData.append(key, val.toISOString());
    } else if (val instanceof File || val instanceof Blob || typeof val === 'string') {
      formData.append(key, val);
    } else if (typeof val === 'object') {
      for (const [subkey, subval] of Object.entries(val ?? {})) {
        addToFormData(formData, `${key}[${subkey}]`, subval, includeAll);
      }
    } else {
      formData.append(key, val as Blob);
    }
  }
  return formData;
}

function addToFormDataRecursive(formData: FormData, form: FormType, includeAll: boolean, keyPrefix = ''): FormData {
  for (const [key, val] of Object.entries(form)) {
    if (val === null) {
      //value is null, add empty string so the backend knows to nullify this value
      formData.append(key, '');
      continue;
    } else if (val === undefined) {
      // value is empty, don't attach to formData
      continue;
    }

    // else if (typeof val === 'boolean') {
    //   formData.append(key, val ? true : false);
    // }

    if (isFormTypeOption(val)) {
      // value has simple type, and can be added directly to the formData
      addToFormData(formData, `${keyPrefix}${key}`, val, includeAll);
    } else {
      // loop over sub-values and check if they can be added directly
      val.forEach((subval: FormType | FormTypeOption, index: number) => {
        const nextKey = `${keyPrefix}${key}[${index}]`;
        if (isFormTypeOption(subval)) {
          addToFormData(formData, nextKey, subval, includeAll);
        } else {
          // could not add directly, call function recursively
          addToFormDataRecursive(formData, subval, includeAll, nextKey);
        }
      });
    }
  }
  return formData;
}

export function convertToFormData(form: FormType, includeAll = false): FormData {
  return addToFormDataRecursive(new FormData(), form, includeAll);
}
