<template>
  <Modal v-if="show" v-slot="{ closeModal }" v-model:show="show" size="md">
    <h3 id="modal-headline" class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-400">
      <slot name="title">
        <i18n-t scope="global" keypath="components.ConfirmPasswordModal.title" />
      </slot>
    </h3>
    <div class="mt-2">
      <p class="text-sm text-gray-500">
        <slot name="description">
          <i18n-t scope="global" keypath="components.ConfirmPasswordModal.description" />
        </slot>
      </p>
    </div>
    <div class="mt-4">
      <TextInput v-model:value="form.password" v-model:errors="errors.password" name="password" :label="$t('components.ConfirmPasswordModal.currentPassword.label')" password />
    </div>
    <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
      <VastgoedButton :text="$t('components.ConfirmPasswordModal.confirm')" :size="ButtonSize.MD" class="" @click="confirmPassword" />

      <VastgoedButton :text="$t('components.addUserModal.cancel')" :color="ButtonColor.WHITE" :size="ButtonSize.MD" class="mx-3" @click="closeModal" />
    </div>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { ConfirmPasswordForm } from '@/store/modules/passwordConfirm';

import TextInput from '@/components/input/Text.vue';

import useForm from '@/composables/Form';

import { isAxiosError } from '@/helpers/AxiosHelper';

import store from '@/store';
import Modal from '@/wrappers/Modal.vue';

export default defineComponent({
  components: {
    TextInput,
    Modal
  },
  setup() {
    return useForm<ConfirmPasswordForm>({
      password: ''
    });
  },
  computed: {
    show: {
      get(): boolean {
        return store.state.passwordConfirm.showPasswordConfirm;
      },
      set(value: boolean) {
        store.commit.passwordConfirm.setShowPasswordConfirm(value);
      }
    }
  },
  methods: {
    confirmPassword() {
      store.dispatch.passwordConfirm.confirmPassword(this.form).catch((err) => {
        if (isAxiosError(err)) {
          if (err.response?.data.errors) {
            this.setFormErrors(err.response.data.errors);
          } else {
            store.dispatch.notification.setError(err.response?.data.message ?? err.message);
          }
        } else if (err instanceof Error) {
          store.dispatch.notification.setError(err.message);
        } else {
          throw err;
        }
      });
    }
  }
});
</script>
