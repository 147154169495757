import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';

import GBE from './assets/lang/en_GB.json';
import NLD from './assets/lang/nl_NL.json';
import { LanguageKey } from './helpers/LocaleHelper';

NLD.APP_DOMAIN = import.meta.env.VITE_DOMAIN;

export const defaultLanguage: LanguageKey = 'nl-NL';

const i18n = createI18n({
  locale: defaultLanguage,
  messages: {
    'nl-NL': NLD,
    'en-GB': GBE
  }
});

export function setI18nLanguage(lang: LanguageKey): LanguageKey {
  i18n.global.locale = lang;
  // https://www.w3schools.com/tags/ref_language_codes.asp
  document.querySelector('html')!.setAttribute('lang', lang.slice(0, 2));
  return lang;
}

export async function loadLanguageAsync(locale: LanguageKey) {
  const messages = await import(`./assets/lang/${locale.replace('-', '_')}.json`).then((messages) => messages.default || messages);
  i18n.global.setLocaleMessage(locale, messages);

  await nextTick();

  return locale;
}

export default i18n;
