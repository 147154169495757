<template>
  <teleport to="#modal-teleport-target">
    <div v-show="show || showWithDelay" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block">
        <!-- Background overlay -->
        <transition
          enter-active-class="ease-out duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-if="show" class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <!-- Modal panel -->
        <transition
          enter-active-class="ease-out duration-300"
          enter-from-class="opacity-0 scale-90"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-90"
        >
          <div
            v-if="show || useShowInsteadIf"
            v-show="show || !useShowInsteadIf"
            v-click-outside="handleClickOutside"
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 dark:bg-gray-800"
            :class="modalClass"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div v-if="!unCloseable" class="absolute top-0 right-0 pt-4 pr-4 z-40">
              <button
                type="button"
                class="bg-white rounded-md text-gray-400 p-2 hover:text-gray-500 focus:outline-none dark:bg-gray-800 transition duration-150"
                @click="closeModal()"
              >
                <span class="sr-only">Close</span>
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <slot v-bind="{ closeModal }" />
          </div>
        </transition>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

import { useModalLogic } from '@/composables/ModalLogic';

export default defineComponent({
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: undefined
    },
    unCloseable: Boolean,
    noLightDismiss: Boolean,
    queryKey: {
      type: String,
      required: false
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'>,
      default: 'md'
    },
    renderSlotWhenClosed: Boolean
  },
  emits: {
    'update:show': (val: boolean) => typeof val === 'boolean'
  },
  setup(props, context) {
    return useModalLogic(props, context);
  },
  computed: {
    useShowInsteadIf(): boolean {
      return this.renderSlotWhenClosed;
    },
    modalClass(): string {
      switch (this.size) {
        case 'sm':
          return 'sm:max-w-lg w-full';
        case 'lg':
          return 'sm:max-w-2xl w-full';
        case 'xl':
          return 'w-full sm:max-w-4xl';
        case '2xl':
          return 'w-full sm:max-w-6xl';
        case '3xl':
          return 'w-full sm:max-w-7xl';
      }
      return 'sm:max-w-lg sm:w-full';
    }
  }
});
</script>
