<template>
  <router-link
    :to="maintenanceEmployee ? { name: 'am.company.switch' } : { name: 'company.switch' }"
    class="text-xs md:text-sm flex items-center text-gray-400 hover:text-gray-500 transition duration-200 truncate ..."
  >
    <svg class="w-5 h-5 mb-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
      ></path>
    </svg>
    <span class="ml-2 font-medium">{{ company?.name }}</span>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { maintenanceEmployee } from '@/views/maintenance';

import store from '@/store';

const company = computed(() => store.state.company.publicCompany ?? null);
</script>
