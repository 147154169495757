import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import { middlewareLogger } from '@/router/Logger';

import { isCompanyDomain } from '@/helpers/SubdomainHelper';
import { hasCompanyRole, hasManagementRole } from '@/helpers/api/RoleableHelper';

import i18n from '@/i18n';
import store from '@/store';

const t = i18n.global.t;

export default (_to: RouteLocation, _from: RouteLocation, next: Next) => {
  const userRole = store.getters.auth.userRole;

  if (isCompanyDomain()) {
    if (!hasCompanyRole(userRole) && !hasManagementRole(userRole)) {
      store.dispatch.notification.setError(t('router.errors.userRole', { userRole }));
      middlewareLogger('role:ownerOrManagement: ', userRole);
      next({ name: 'dashboard' });
      return false;
    }
  }
};
