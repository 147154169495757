import { LocationQueryValue } from 'vue-router';

import router from '@/router';

export function openModal(key: string, params?: string[] | string) {
  const currentRoute = router.currentRoute.value;
  return router.push({
    hash: currentRoute.hash,
    query: { ...currentRoute.query, [key]: params ?? 'true' }
  });
}

export function closeModal(key: string) {
  const currentRoute = router.currentRoute.value;

  // remove unused query
  const { [key]: _, ...modQuery } = currentRoute.query;

  return router.push({ ...currentRoute, query: modQuery });
}

export function isModalOpen(key: string) {
  const currentRoute = router.currentRoute.value;
  return key in currentRoute.query && !!currentRoute.query[key];
}

export function toggleModal(key: string, params?: string[] | string) {
  if (isModalOpen(key)) {
    closeModal(key);
  } else {
    openModal(key, params);
  }
}

export function getQueryValue(key: string): LocationQueryValue | LocationQueryValue[] {
  const currentRoute = router.currentRoute.value;
  return currentRoute.query[key];
}
