import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import store from '@/store';

import { middlewareLogger } from '../Logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (to: RouteLocation, from: RouteLocation, next: Next) => {
  if (store.state.auth.isLoading) {
    //console.warn("User still loading, unsure flag set to true");
    store.commit.auth.setUnsure(true);
  }
  if (store.getters.auth.isAuthenticated) {
    middlewareLogger('guest');
    next({ name: 'dashboard' });
    return false;
  }
};
