// https://stackoverflow.com/questions/59940863/how-to-properly-type-event-handler-in-wrapped-addeventlistener-call-in-typescrip
import { eventListenerLogger } from './Logger';
import { addRemoveEventListenerCallback } from './ManualDeletion';

// for logging only
let activeListeners = [] as EventListenerOrEventListenerObject[];

// addEventL-istener adhering to the open-closed-principle

function addEventListener<K extends keyof WindowEventMap>(
  target: Window,
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): () => void;

function addEventListener<K extends keyof HTMLElementEventMap>(
  target: HTMLElement,
  type: K,
  listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): () => void;

function addEventListener<K extends keyof DocumentEventMap>(
  target: Document,
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): () => void;

function addEventListener(
  target: HTMLElement | Window | Document,
  type: string,
  listener: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions
): () => void {
  target.addEventListener(type, listener, options);
  activeListeners.push(listener);
  eventListenerLogger('(Added). Current:', activeListeners);

  const callback = () => {
    target.removeEventListener(type, listener, options);
    activeListeners = activeListeners.filter((l) => l != listener);
    eventListenerLogger('(Removed). Current:', activeListeners);
  };
  addRemoveEventListenerCallback(callback);
  return callback;
}

export { addEventListener };
