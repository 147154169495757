import { App } from 'vue';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import { addEventListener } from './helpers/javascript/AddEventListener';

// see: https://medium.com/js-dojo/error-exception-handling-in-vue-js-application-6c26eeb6b3e4
// and https://qiita.com/ProjectEuropa/items/19522db5fa37aabbe25a

export default {
  install: (app: App) => {
    if (import.meta.env.PROD) {
      Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_URL,
        release: import.meta.env.VITE_SENTRY_RELEASE_NAME,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0
      });

      app.config.errorHandler = (err, vm, info) => {
        Sentry.captureException(err);
      };

      const removeErrorListener = addEventListener(window, 'error', (event: ErrorEvent) => {
        Sentry.captureException(event);
      });
      const removeUnhandledRejectionListener = addEventListener(window, 'unhandledrejection', (event: PromiseRejectionEvent) => {
        Sentry.captureException(event);
      });

      window.addEventListener(
        'unload',
        () => {
          removeErrorListener();
          removeUnhandledRejectionListener();
        },
        { once: true }
      );
    }
  }
};
