import { computed } from 'vue';

import TableConfig from '@/components/table/helpers/types';

import { renderRepairSeverity } from '@/models/Repair';
import { Repair, renderRepairStatus, repairStatusColorMap } from '@/models/RepairInternal';

import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import { RoleIds } from '@/types/roles';

const { t } = i18n.global;

export const maintenanceEmployee = computed<boolean>(() => store.state.auth.user?.selectedRoleable!.role_id === RoleIds.maintenance_employee);

export const maintenanceReportTableConfig = computed<TableConfig<Repair>>(() => ({
  sortables: ['created_at'],
  header: [
    {
      key: 'created_at',
      text: t('views.dashboard.maintenance.created_at'),
      column: {
        type: 'date'
      }
    },
    {
      key: 'propertyAddress',
      text: t('views.dashboard.maintenance.propertyAddress')
    },
    {
      key: 'severity',
      text: t('views.dashboard.maintenance.severity'),
      textAlign: 'right',
      column: {
        type: {
          kind: 'pill',
          colors: new Map([
            [1, 'gray'],
            [2, 'yellow'],
            [3, 'red'],
            [4, 'red-flashing']
          ])
        },
        valueModification: (repair) => renderRepairSeverity(repair.severity),
        style: 'text-right'
      }
    },
    {
      key: 'status',
      text: t('views.dashboard.maintenance.status'),
      textAlign: 'right',
      column: {
        type: {
          kind: 'pill',
          colors: repairStatusColorMap
        },
        valueModification: ({ status }) => renderRepairStatus(status),
        style: 'text-right'
      }
    }
  ],
  rowCallback: (row) => {
    router.push({
      name: 'am.maintenance.report.show',
      params: { id: row.id }
    });
  }
}));
