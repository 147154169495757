<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol class="flex items-center space-x-2 unselectable">
      <li>
        <div>
          <router-link :to="{ name: 'dashboard' }" class="text-xs md:text-sm font-medium text-gray-400 hover:text-gray-400 transition duration-100">
            <!-- Heroicon name: home -->
            <svg class="flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
              />
            </svg>
            <span class="sr-only">
              <i18n-t scope="global" keypath="components.breadcrumbs.dashboard" />
            </span>
          </router-link>
        </div>
      </li>
      <li v-for="crumb in breadcrumbs" :key="crumb.title">
        <router-link v-if="crumb.to" :to="crumb.to">
          <div class="flex items-center">
            <svg class="flex-shrink-0 h-4 w-4 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <a href="#" class="ml-1 text-xs md:text-sm font-medium text-gray-500 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 transition duration-100">
              {{ crumb.title }}
            </a>
          </div>
        </router-link>
        <div v-else class="flex items-center">
          <svg class="flex-shrink-0 h-4 w-4 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <a href="#" class="ml-1 text-xs md:text-sm font-medium text-gray-500 hover:text-gray-400 transition duration-100">
            {{ crumb.title }}
          </a>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { BreadCrumbItem } from '@/components/breadcrumbs/breadcrumbsGenerator';

import breadcrumbs from '@/breadcrumbs';

export default defineComponent({
  name: 'Breadcrumbs',
  props: {
    name: {
      required: false,
      type: String
    },
    props: {
      default: () => {
        return [];
      },
      type: [Array, Object]
    }
  },
  computed: {
    breadcrumbs(): Array<BreadCrumbItem> {
      const name = this.name ?? this.$route.name;
      if (!name || typeof name === 'symbol') {
        return [];
      }
      // catch dashboard and don't throw an error
      if (name === 'company.dashboard') {
        return [];
      }
      const args = Array.isArray(this.props) ? this.props : [this.props];
      return breadcrumbs.generate(name, ...args);
    }
  }
});
</script>
