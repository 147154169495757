<template>
  <div :class="{ dark: prefersDarkMode }">
    <LoadingWrapper :show-loading="!showApplication">
      <router-view />
      <PageMetaData :title="company?.name" />
    </LoadingWrapper>

    <div id="modal-teleport-target" />
    <Notifications />
    <Reauthorize />
    <PaywallModal />
    <ConfirmPasswordModal />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import guestMiddleware from '@/router/middleware/guest';

import PageMetaData from '@/components/PageMetaData.vue';
import ConfirmPasswordModal from '@/components/modals/ConfirmPasswordModal.vue';
import Notifications from '@/components/notifications/Wrapper.vue';
import PaywallModal from '@/components/paywall/Modal.vue';

import { wrapInArray } from '@/helpers/javascript/ArrayHelper';

import service from '@/axios';
import store from '@/store';
import LoadingWrapper from '@/wrappers/Loading.vue';

import { isCompanyDomain } from './helpers/SubdomainHelper';
import { getFirstQueryValue } from './helpers/vue-routerHelper';
import { defaultLanguage, setI18nLanguage } from './i18n';
import { AuthUser } from './models/User';
import { middlewareLogger } from './router/Logger';
import { RoleIds } from './types/roles';
import Reauthorize from './views/company/Bizcuit/reauthorize/Reauthorize.vue';

export default defineComponent({
  name: 'App',
  components: {
    ConfirmPasswordModal,
    LoadingWrapper,
    PaywallModal,
    Notifications,
    PageMetaData,
    Reauthorize
  },
  computed: {
    company() {
      return store.state.company.publicCompany;
    },
    user(): AuthUser | undefined {
      return store.state.auth.user;
    },
    prefersDarkMode(): boolean {
      return this.user?.prefersDarkMode ?? false;
    },
    showApplication(): boolean {
      if (store.state.auth.isLoading || store.state.auth.unsure) {
        return false;
      }

      const isNonCompanyDomain = !isCompanyDomain();
      const isNonCompanyRoute = !!this.$route.meta.companyless;

      return isNonCompanyDomain || isNonCompanyRoute || !!this.company;
    },
    activeMiddleware(): unknown[] {
      return wrapInArray(this.$route.meta.middleware);
    },
    intendedRoute(): RouteLocationRaw {
      return getFirstQueryValue(this.$route.query.next) ?? { name: 'dashboard' };
    }
  },
  watch: {
    $route(route) {
      // console.log("route watch triggerd", route);
      this.redirectBasedOnMiddleware();
    },
    user(user) {
      // console.log("user watch triggerd", user);
      this.redirectBasedOnMiddleware();
    }
  },
  created() {
    this.getCompany();
    this.getUser();
  },
  methods: {
    getCompany() {
      // call api to get company details (name, logo, etc.)
      store.dispatch.company.getPublicCompanyData().then((company) => {
        if (!company && isCompanyDomain()) {
          this.$router.replace({ name: 'companyNotFound' });
        }
      });
    },
    redirectToIntentedRoute() {
      middlewareLogger('redirected by App.vue');

      const selectedRoleId = this.user?.selectedRoleable?.role_id;

      // These IDs must match RoleIds in the backend.
      if (selectedRoleId === RoleIds.handyman || selectedRoleId === RoleIds.service_mechanic) {
        console.warn('Redirection attempt as handyman/service_mechanic intercepted. Navigating to capture page.');
        store.dispatch.auth.logout();
        window.location.replace(`${import.meta.env.VITE_MAIN_DOMAIN}/download-onderhoud-app`);
        throw new Error('Cannot login as handyman/service_mechanic');
      } else {
        this.$router.replace(this.intendedRoute).then(() => {
          store.commit.auth.setUnsure(false);
        });
      }
    },
    redirectIfUserOnGuestPage() {
      const isGuestPage = this.activeMiddleware.includes(guestMiddleware);
      if (this.user && isGuestPage) {
        this.redirectToIntentedRoute();
      }
    },
    redirectIfUserHasSelectedRole() {
      const hasSelectedRole = !!this.user?.selectedRoleable;
      const onSelectRolePage = !!this.$route.meta.isRoleSelectionPage;
      if (hasSelectedRole && onSelectRolePage) {
        this.redirectToIntentedRoute();
      }
    },
    redirectBasedOnMiddleware() {
      this.redirectIfUserOnGuestPage();
      this.redirectIfUserHasSelectedRole();
    },
    async getUser() {
      // get the user at app startup to check if their credentials are still valid.
      // On a 401, the application automaticly resets to guest state
      try {
        const user = await store.dispatch.auth.getUser();
        setI18nLanguage(user?.locale || defaultLanguage);
        service.defaults.headers.common['Accept-Language'] = user?.locale;
        //return console.log('usercheck done', user);
      } catch (error) {
        /** */
      }
    }
  }
});
</script>

<style>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
