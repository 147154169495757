import { Ref, SetupContext, inject, onMounted, onUnmounted, provide, ref, watch } from 'vue';
import { LocationQueryValue, onBeforeRouteLeave, useRoute } from 'vue-router';

import { closeModal as closeModalByModifyingQuery } from '@/helpers/ModalHelper';
import { addEventListener } from '@/helpers/javascript/AddEventListener';

type Context = SetupContext<{
  'update:show': (val: boolean) => boolean;
}>;

type ModalLogic = {
  closeModal: () => void;
  handleClickOutside: () => void;
  handleKeydown: (e: KeyboardEvent) => void;
  show: Ref<boolean | undefined>;
  showWithDelay: Ref<boolean | undefined>;
};

type Props = {
  show?: boolean;
  unCloseable: boolean;
  noLightDismiss: boolean;
  queryKey?: string;
};

let canClose = true;

function disableClosingFor(ms: number) {
  canClose = false;

  setTimeout(() => {
    canClose = true;
  }, ms);
}

// the basic idea: pass a reactive boolean to the (direct) Modal children.
// Let them modify the boolean.
// if one of the child modals is active; don't allow closing this modal.
// Note: potential bug: parent boolean is set to false by sibling, while this modal is still open
// Note: this solution doesn't look at grandchildren (or further down the chain), but should work implicitly:
// the use case for an closed modal that opens an modal seems non-existent
function setupHasActiveChildren(showCurrentModal: Ref<boolean>): Ref<boolean> {
  // get injection value from parents (to be modified)
  const parentActiveBoolean = inject<Ref<boolean>>(
    'hasActiveChildren',
    ref(false) // or an meaningless boolean if no parent exists
  );

  // if current modal becomes (in)active, let the parents know
  watch(showCurrentModal, (show) => (parentActiveBoolean.value = show), {
    immediate: true
  });

  // pass boolean to children (they can modify it)
  const hasActiveChildren = ref(false);
  provide('hasActiveChildren', hasActiveChildren);

  return hasActiveChildren;
}

function useExternalShowState(props: Props, context: Context): ModalLogic {
  if (props.show === undefined) {
    throw new Error('called useExternalShowState function without show prop');
  }
  const show = ref(props.show);
  const hasActiveChildren = setupHasActiveChildren(show);
  const showWithDelay = ref(show.value);

  watch(
    () => props.show,
    (newShow) => {
      if (newShow === undefined) {
        throw new Error('called useExternalShowState function without show prop');
      }
      show.value = newShow;

      // prevent closing the modal directly after opening it, or closing another
      disableClosingFor(100);

      setTimeout(() => {
        showWithDelay.value = newShow;
      }, 300);
    }
  );

  const closeModal = () => {
    if (!props.unCloseable && canClose && !hasActiveChildren.value) {
      context.emit('update:show', false);
    }
  };
  const handleClickOutside = () => {
    if (canClose && !props.noLightDismiss) {
      closeModal();
    }
  };
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && show.value) {
      closeModal();
    }
  };

  let removeKeydownEventListenerCallback = () => {
    return;
  };
  onMounted(() => {
    removeKeydownEventListenerCallback = addEventListener(document, 'keydown', handleKeydown);
  });
  onUnmounted(() => {
    removeKeydownEventListenerCallback();
  });

  // intercept route leave and close modal if open, instead of navigating.
  onBeforeRouteLeave((_to, _from, next) => {
    if (show.value) context.emit('update:show', false);
    next();
  });

  return {
    closeModal,
    handleClickOutside,
    handleKeydown,
    show,
    showWithDelay
  };
}

function useQueryState(props: Props, context: Context): ModalLogic {
  const queryKey = props.queryKey;
  if (!queryKey) {
    throw new Error('called useQueryState function without queryKey prop');
  }
  const route = useRoute();

  const show = ref(false);
  const hasActiveChildren = setupHasActiveChildren(show);

  watch(
    () => show.value,
    () => {
      // prevent closing the modal directly after opening it, or closing another
      disableClosingFor(100);
    }
  );

  const closeModal = () => {
    if (!props.unCloseable && canClose && !hasActiveChildren.value) {
      closeModalByModifyingQuery(queryKey);
    }
  };
  const handleClickOutside = () => {
    if (!props.noLightDismiss) {
      closeModal();
    }
  };
  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && show.value) {
      closeModal();
    }
  };

  let removeKeydownEventListenerCallback = () => {
    return;
  };
  onMounted(() => {
    removeKeydownEventListenerCallback = addEventListener(document, 'keydown', handleKeydown);
  });
  onUnmounted(() => {
    removeKeydownEventListenerCallback();
  });

  // Code for listening to the query params
  const handleQueryKeyChange = (queryValue: LocationQueryValue | LocationQueryValue[]) => {
    if (queryValue) {
      show.value = true;
    } else {
      show.value = false;
    }
  };
  watch(() => route.query[queryKey], handleQueryKeyChange, {
    immediate: true
  });

  // still emit show value changes in case parent components need to listen for it
  watch(
    () => show.value,
    (show) => {
      context.emit('update:show', show);
    }
  );

  const showWithDelay = ref(show.value);
  watch(
    () => show.value,
    (show) => {
      setTimeout(() => {
        showWithDelay.value = show;
      }, 300);
    }
  );

  return { closeModal, handleClickOutside, handleKeydown, show, showWithDelay };
}

export const useModalLogic = (props: Props, context: Context): ModalLogic => {
  if (props.show !== undefined) {
    return useExternalShowState(props, context);
  } else if (props.queryKey !== undefined) {
    return useQueryState(props, context);
  } else {
    throw new Error('Use show or queryKey prop in ModalLogic');
  }
};
