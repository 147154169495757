import axios from 'axios';
import { ref } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import i18n from '@/i18n';

import { paywall } from './components/paywall/paywall';
import { nodeEnvIsDevelopment } from './helpers/EnvironmentHelper';
import router from './router';
import authMiddleware from './router/middleware/auth';
import store from './store';
import { showReauthorizeModal } from './views/company/Bizcuit/repository/BizcuitAuthRepository';

const { t } = i18n.global;

export type Middleware = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => void;

const service = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  withXSRFToken: true
});

interface ErrorHandlers {
  [key: number]: (error: any) => void;
}

export const responseErrorMapWithoutMessage = ref<ErrorHandlers>({
  402: (error) => {
    paywall.value.show = true;
    paywall.value.reason = error.response.data.message;
  },
  556: (error) => {
    showReauthorizeModal.value = true;
  }
});

export const responseErrorMapWithMessage = ref<ErrorHandlers>({
  401: (error) => {
    const currentRouteMiddleware = router.currentRoute.value.meta.middleware as Middleware[] | undefined;
    const currentPath = router.currentRoute.value.fullPath;
    if (currentPath === '/') return;

    if (currentRouteMiddleware !== undefined && currentRouteMiddleware.includes(authMiddleware)) {
      store.dispatch.notification.setError(t('axios.401'));
      store.commit.auth.removeUser();
      router.replace({ name: 'login', params: { next: currentPath } });
    }
  },
  423: (error) => {
    store.commit.passwordConfirm.handle423();
  },
  422: (error) => store.dispatch.notification.setError(error.response?.data?.message ?? error.message),
  419: (error) => {
    const currentRouteMiddleware = router.currentRoute.value.meta.middleware as Middleware[] | undefined;
    const currentPath = router.currentRoute.value.fullPath;

    store.commit.auth.removeUser();
    if (currentRouteMiddleware !== undefined && currentRouteMiddleware.includes(authMiddleware)) {
      store.dispatch.notification.setError(t('axios.419'));
      router.replace({ name: 'login', params: { next: currentPath } });
    } else {
      location.reload();
    }
  }
});

const handleErrorResponse = (error: any): Promise<void> => {
  const errorStatusHandler = {
    ...responseErrorMapWithMessage.value,
    ...responseErrorMapWithoutMessage.value
  }[error.response.status];

  if (errorStatusHandler) {
    errorStatusHandler(error);
  } else if (!error.response) {
    store.dispatch.notification.setError(t('axios.noResponse'));
  } else if (error.response.status >= 500) {
    if (nodeEnvIsDevelopment()) {
      error.message = error.response?.data?.message ?? error.message;
    } else {
      store.dispatch.notification.setError(t('axios.5xx'));
    }
  }

  return Promise.reject(error);
};

service.interceptors.response.use(
  (response) => response,
  (error) => handleErrorResponse(error)
);

export const axiosWithoutInterceptors = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true
});

export default service;
