import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import { middlewareLogger } from '@/router/Logger';

import { isServiceDomain } from '@/helpers/SubdomainHelper';
import { hasServiceCompanyRole } from '@/helpers/api/RoleableHelper';

import i18n from '@/i18n';
import store from '@/store';

const t = i18n.global.t;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (to: RouteLocation, from: RouteLocation, next: Next) => {
  const userRole = store.getters.auth.userRole;

  if (isServiceDomain()) {
    if (!hasServiceCompanyRole(userRole)) {
      store.dispatch.notification.setError(t('router.errors.userRole', { userRole }));
      middlewareLogger('role:service: ', userRole);
      next({ name: 'dashboard' });
      return false;
    }
  }
};
