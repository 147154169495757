<template>
  <div :class="customClass">
    <label v-if="label || required" :for="name" class="label" :class="{ 'opacity-50': disabled }">
      {{ label }}
      <span v-if="required" class="text-red-500" :title="$t('general.required')"> * </span>
    </label>
    <StatusWrapper v-slot="{ hasErrors }" :html-name="name" :errors="errors" :success="success">
      <input
        :id="name"
        ref="inputElement"
        :type="type"
        :class="[
          'block w-full bg-white input-md rounded-md border-0 placeholder-gray-400 focus:outline-none focus:ring-0 dark:bg-gray-700 dark:text-gray-300',
          { 'pointer-events-none cursor-not-allowed opacity-50': disabled },
          inputClass
        ]"
        :aria-invalid="hasErrors"
        :aria-describedby="name + hasErrors ? '-error' : ''"
        autocomplete="nope"
        :autocapitalize="autocapitalizeBool"
        v-bind="attrsWithoutClass"
        @input="updateValue()"
        @change="clearErrors()"
      />
      <div v-if="prefix" class="h-full bg-gray-50 input-md border-l border-gray-200 flex items-center justify-center dark:bg-gray-800 dark:border-gray-600">
        <span style="margin: 2px 0px" class="text-xs md:text-sm text-gray-600 dark:text-gray-300">
          <template v-if="prefix == 'surface'"> m&#178; </template>
          <template v-else>
            {{ prefix }}
          </template>
        </span>
      </div>
    </StatusWrapper>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import StatusWrapper from './StatusWrapper.vue';

const props = withDefaults(
  defineProps<{
    name: string;
    value?: number | string | null;
    errors?: string[];
    success?: boolean;
    label?: string;
    password?: boolean;
    required?: boolean;
    placeholder?: string;
    class?: string;
    inputClass?: string;
    inputType?: string;
    disabled?: boolean;
    nullable?: boolean;
    prefix?: string;
    regexp?: string | RegExp;
    regexpErrorMessage?: string;
    autocapitalize?: string;
  }>(),
  {
    errors: () => [],
    label: '',
    placeholder: '',
    regexpErrorMessage: 'Does not satisfy regex'
  }
);

const emits = defineEmits<{
  (e: 'update:value', value: string): void;
  (e: 'update:errors', value: unknown): void;
}>();

const inputElement = ref<HTMLInputElement>();
const customClass = ref<string | undefined>(props.class);

const autocapitalizeBool = computed(() => (props.autocapitalize ? 'on' : 'off'));

const type = computed(() => props.inputType || (props.password ? 'password' : 'text'));

const attrsWithoutClass = computed(() => {
  const { class: _class, prefix: _prefix, autocapitalize: _autocapitalize, ...attrs } = props;
  return attrs;
});

function updateValue() {
  clearErrors();

  if (props.nullable && inputElement.value?.value === '') {
    return emits('update:value', '');
  }

  emits('update:value', inputElement.value?.value ?? '');
  checkRegex(inputElement.value?.value);
}

function checkRegex(value?: string) {
  if (!value) return;
  if (!props.regexp) return;
  const re = new RegExp(props.regexp);

  const regexError = new Array(props.regexpErrorMessage);

  if (!re.test(value)) {
    emits('update:errors', regexError);
  }
}

function clearErrors() {
  emits('update:errors', []);
}
</script>
