import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { isManagementDomain, isServiceDomain } from '@/helpers/SubdomainHelper';
// --------------------- middleware --------------------- //
import { hasCompanyRole, hasMaintenanceRole, hasManagementRole, hasServiceCompanyRole, hasTenantRole } from '@/helpers/api/RoleableHelper';

import store from '@/store';

// ----------------------- views ----------------------- //
import Welcome from '../views/guest/Welcome.vue';
import WelcomeManagement from '../views/management/guest/Welcome.vue';
import WelcomeService from '../views/service/guest/Welcome.vue';
import { maintenanceRoutes } from './authMaintenanceRoutes';
import authManagementRoutes from './authManagementRoutes';
import authRoutes from './authRoutes';
import authServiceRoutes from './authServiceRoutes';
import authTenantRoutes from './authTenantRoutes';
import auth from './middleware/auth';
import companyDomain from './middleware/domain/company';
import managementDomain from './middleware/domain/management';
import serviceDomain from './middleware/domain/service';
import guest from './middleware/guest';
import hasRoleSelected from './middleware/hasRoleSelected';
import log from './middleware/log';
import maintenance from './middleware/role/maintenance';
import ownerOrManagement from './middleware/role/ownerOrManagement';
import service from './middleware/role/service';
import tenant from './middleware/role/tenant';

const AuthWrapper = () => import('@/wrappers/auth/Company.vue');
const MaintenanceAuthWrapper = () => import('@/wrappers/auth/Maintenance.vue');
const TenantAuthWrapper = () => import('@/wrappers/auth/Tenant.vue');
const ServiceAuthWrapper = () => import('@/wrappers/auth/Service.vue');
const ManagementAuthWrapper = () => import('@/wrappers/auth/Management.vue');

const companyRoutes: Array<RouteRecordRaw> = [
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
    meta: {
      middleware: [log, guest, companyDomain]
    }
  },
  {
    path: '/a',
    component: AuthWrapper,
    meta: {
      middleware: [log, auth, ownerOrManagement]
    },
    children: authRoutes
  },
  {
    path: '/am',
    component: MaintenanceAuthWrapper,
    meta: {
      middleware: [log, auth, maintenance]
    },
    children: maintenanceRoutes
  }
];

const serviceRoutes: Array<RouteRecordRaw> = [
  {
    path: '/s/welcome',
    name: 'service.welcome',
    component: WelcomeService,
    meta: {
      middleware: [log, guest, serviceDomain]
    }
  },
  {
    path: '/s',
    component: ServiceAuthWrapper,
    meta: {
      middleware: [log, auth, service, hasRoleSelected]
    },
    children: authServiceRoutes
  }
];

const managementRoutes: Array<RouteRecordRaw> = [
  {
    path: '/m',
    component: ManagementAuthWrapper,
    meta: {
      middleware: [log, auth, managementDomain]
    },
    children: authManagementRoutes
  },
  {
    path: '/m/welcome',
    name: 'management.welcome',
    component: WelcomeManagement,
    meta: {
      middleware: [log, guest, managementDomain]
    }
  }
];

const tenantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/t/:tenant/intake/:intake',
    name: 'tenant.guest.intake',
    component: () => import('@/views/tenant/guest/Intake.vue'),
    meta: {
      middleware: [log, companyDomain]
    },
    props: (route: RouteLocationNormalized) => {
      const intake = +route.params.intake;
      const tenant = +route.params.tenant;
      const email = route.query.hash;
      const details = +route.params.details;

      return { ...route.params, details, intake, tenant, email };
    }
  },
  {
    path: '/t/repair/explanation',
    name: 'tenant.repair.explanation',
    component: () => import('@/views/tenant/guest/RepairExplanation.vue'),
    meta: {
      middleware: [log, guest]
    },
    props: true
  },
  {
    path: '/t/repair/:propertyUuid',
    name: 'tenant.repair',
    component: () => import('@/views/tenant/Repair.vue'),
    meta: {
      middleware: [log]
    },
    props: true
  },
  {
    path: '/t',
    component: TenantAuthWrapper,
    meta: {
      middleware: [log, auth, companyDomain, tenant]
    },
    children: authTenantRoutes
  }
];

const miscRoutes: Array<RouteRecordRaw> = [
  {
    name: 'role.select',
    path: '/select/roleable',
    component: () => import('@/views/RoleSwitcher.vue'),
    meta: {
      middleware: [log, auth],
      isRoleSelectionPage: true
    }
  },
  {
    // this path is used to redirect to if the application isn't sure what
    // the user role is. It redirects to the appropriate dashboard.
    path: '/dashboard/redirect',
    name: 'dashboard',
    redirect: () => {
      const userRole = store.getters.auth.userRole;
      if (!userRole) {
        return { name: 'login' };
      }

      if (isServiceDomain()) {
        if (!hasServiceCompanyRole(userRole)) {
          throw new Error("On service domain, but user doesn't have correct permissions: " + JSON.stringify(userRole));
        }
        return { name: 'service.dashboard' };
      } else if (isManagementDomain()) {
        if (!hasManagementRole(userRole)) {
          throw new Error("On management domain, but user doesn't have correct permissions: " + JSON.stringify(userRole));
        }
        return { name: 'management.dashboard' };
      } else if (hasCompanyRole(userRole)) {
        return { name: 'company.dashboard' };
      } else if (hasMaintenanceRole(userRole)) {
        return { name: 'maintenance.dashboard' };
      } else if (hasTenantRole(userRole)) {
        return { name: 'tenant.dashboard' };
      }

      throw new Error("Tried to visit 'dashboard/redirect' route and could not find viable dashboard to redirect to. Userrole: " + JSON.stringify(userRole));
    },
    meta: {
      middleware: [log]
    }
  },

  {
    path: '/api/tenant/:tenant/intake/:intake',
    redirect: ({ params, query }) => {
      return { name: 'tenant.guest.intake', params, query };
    },
    meta: {
      middleware: [log, companyDomain]
    }
  }
];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      if (isManagementDomain()) {
        return { name: 'management.welcome' };
      }
      if (isServiceDomain()) {
        return { name: 'service.welcome' };
      }
      return { name: 'welcome' };
    },
    meta: {
      middleware: [log]
    }
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/guest/Login.vue'),
    meta: {
      middleware: [log, guest]
    }
  },
  {
    path: '/available',
    name: 'companyNotFound',
    component: () => import('@/views/guest/CompanyNotFound.vue'),
    meta: {
      companyless: true,
      middleware: [log, guest, companyDomain]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      middleware: [log]
    }
  },

  ...companyRoutes,
  ...serviceRoutes,
  ...tenantRoutes,
  ...managementRoutes,
  ...miscRoutes
];

export default routes;

// {
//   path: "/api/onbekende/url/voor/natte/handtekeing",
//   redirect: ({ params, query }) => {
//     return { name: "tenant.guest.agreement.signing", params, query };
//   },
//   meta: {
//     middleware: [log, companyDomain]
//   }
// },
// {
//   path: "/t/sign/agreement/url",
//   name: "tenant.guest.agreement.signing",
//   component: () => import("@/views/tenant/guest/agreement/Signing.vue"),
//   meta: {
//     middleware: [log, companyDomain]
//   },
//   props: true
// },
