import { ActionContext } from 'vuex';

import Company from '@/models/Company';
import { ROLEABLE_TYPE_MANAGEMENT_COMPANY, ROLEABLE_TYPE_SERVICE_COMPANY, Roleable } from '@/models/Rolables';

import { isManagementDomain, isServiceDomain } from '@/helpers/SubdomainHelper';
import { errorHandler } from '@/helpers/api/ErrorHandler';

import backgroundServiceSrc from '@/assets/images/background-service.jpg?url';
import axios from '@/axios';
import i18n from '@/i18n';
import storage from '@/storage';

import { storeLogger } from '../Logger';
import { State as RootState } from '../state';

const { t } = i18n.global;

export interface CompanyState {
  publicCompany?: Company | null;
}
export type PublicCompanyData = Company;
type NotificationContext = ActionContext<CompanyState, RootState>;

export const COMPANY_STORAGE_KEY = '_company';
const CURRENT_SUBDOMAIN = window.location.hostname.split('.')[0];
const PUBLIC_COMPANY_ROUTE = 'company/company/domain/{subdomain}';
const PUBLIC_SERVICE_ROUTE = 'service/service/{company}';
const PUBLIC_MANAGEMENT_ROUTE = 'management/{company}/management/';

const MOCK_SERVICE_DOMAIN = Promise.resolve(() => ({
  id: NaN,
  name: t('viewsService.welcome.title'),
  name_public: t('viewsService.welcome.title'),
  name_display: t('viewsService.welcome.title'),
  logo: 'https://vastgoed.app/assets/images/mail/logo.png',
  icon: 'https://vastgoed.app/assets/images/mail/logo.png',
  cover: backgroundServiceSrc,
  has_custom_logo: null,
  about: t('viewsService.welcome.introText'),
  domain: '',
  town: '',
  website: null,
  availabilities_required: false,
  email_public: null,
  created_at: new Date().toISOString(),
  bizcuit: null,
  bizcuit_connection: false
}));

// ---------------- STATE ---------------- //
const state: CompanyState = {
  publicCompany: storage.get(COMPANY_STORAGE_KEY)
};

// ---------------- MUTATIONS ---------------- //
const mutations = {
  setPublicCompany(state: CompanyState, company: Company) {
    storeLogger('setPublicCompany', state, company);
    state.publicCompany = company;

    storage.set(COMPANY_STORAGE_KEY, company);
  }
};

// ---------------- ACTIONS ---------------- //
const actions = {
  async getPublicCompanyData({ commit }: NotificationContext) {
    if (isServiceDomain() || isManagementDomain()) return MOCK_SERVICE_DOMAIN;

    try {
      const url = PUBLIC_COMPANY_ROUTE.replace('{subdomain}', CURRENT_SUBDOMAIN);
      const { data } = await axios.get<{ company: Company }>(url);
      commit('setPublicCompany', data.company);
      return data.company;
    } catch (error) {
      /** */
    }
  },
  async getPublicServiceCompanyData({ commit }: NotificationContext, roleable?: Roleable): Promise<PublicCompanyData | null> {
    try {
      if (roleable?.roleable_type !== ROLEABLE_TYPE_SERVICE_COMPANY) throw new Error('Cannot get service company data on non service company roleable');

      const url = PUBLIC_SERVICE_ROUTE.replace('{company}', roleable?.roleable_id?.toString() ?? '');
      const { data } = await axios.get<{ service_company: PublicCompanyData }>(url);

      commit('setPublicCompany', data.service_company);
      return data.service_company;
    } catch (error) {
      errorHandler(error);
      commit('setPublicCompany', null);
      return null;
    }
  },
  async getPublicManagementCompanyData({ commit }: NotificationContext, roleable?: Roleable): Promise<PublicCompanyData | null> {
    try {
      if (roleable?.roleable_type !== ROLEABLE_TYPE_MANAGEMENT_COMPANY) throw new Error('Cannot get management company data on non management company roleable');

      const url = PUBLIC_MANAGEMENT_ROUTE.replace('{company}', roleable?.roleable_id?.toString() ?? '');
      const { data } = await axios.get<{ management_company: PublicCompanyData }>(url);
      commit('setPublicCompany', data.management_company);
      return data.management_company;
    } catch (error) {
      errorHandler(error);
      commit('setPublicCompany', null);
      return null;
    }
  }
};

// ---------------- GETTERS ---------------- //
const getters = {};

export default {
  namespaced: true as const,
  state,
  mutations,
  actions,
  getters
};
