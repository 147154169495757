// import { Item as DataItem } from "@/models/GenericApiData";

export type Direction = 'ASC' | 'DESC' | undefined;

export type SortSettings = {
  key: string | undefined;
  dir: Direction;
};

export function rotateDirection(dir: Direction): Direction {
  switch (dir) {
    case 'ASC':
      return undefined;
    case 'DESC':
      return 'ASC';
    default:
      return 'DESC';
  }
}

export function rotateSortSetting(sort: SortSettings, key?: string): SortSettings {
  const dir = rotateDirection(key === sort.key ? sort.dir : undefined);
  return { key, dir };
}

export function sortToQuery(sort: SortSettings, includeQueryKey = true): string | undefined {
  if (sort.dir === undefined || sort.key === undefined) {
    return undefined;
  }
  const dirMod = sort.dir === 'DESC' ? '-' : '';

  if (includeQueryKey) {
    return `sort=${dirMod}${sort.key}`;
  } else {
    return `${dirMod}${sort.key}`;
  }
}

export function queryToSort(query: string | undefined | null): SortSettings {
  if (!query) {
    return {
      key: undefined,
      dir: undefined
    };
  }

  return query.startsWith('-')
    ? {
        dir: 'DESC',
        key: query.substring(1)
      }
    : {
        dir: 'ASC',
        key: query
      };
}
