import { createApp } from 'vue';

import PageHeader from '@/components/PageHeader.vue';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue';
import Companyinfo from '@/components/info/CompanyInfo.vue';

import App from './App.vue';
import './assets/css/tailwind.css';
import clickOutside from './directives/click-outside';
import fullScreenheight from './directives/full-screen-height';
import VastgoedButton from './globalComponents/Button';
import GlobalUserStore from './globalComponents/UserStore';
import i18n from './i18n';
import router from './router';
import sentry from './sentry';
import store from './store';

const app = createApp(App);

app.use(sentry);

// attach components globally:
app.component('Breadcrumbs', Breadcrumbs);
app.component('CompanyInfo', Companyinfo);
app.component('PageHeader', PageHeader);
app.use(VastgoedButton);

app.use(GlobalUserStore);

app.use(clickOutside);
app.use(fullScreenheight);
app.use(store.original);
app.use(router);
app.use(i18n);

app.mount('#app');
