export const RoleIds = {
  manager: 1,
  owner: 2,
  employee: 3,
  service_owner: 4,
  service_employee: 5,
  tenant: 6,
  handyman: 7,
  administrator: 8,
  management_administrator: 9,
  management_employee: 10,
  service_mechanic: 11,
  maintenance_employee: 12
} as const;
