import { errorHandler } from './helpers/api/ErrorHandler';

interface Storage {
  set<T>(key: string, value: T, passphrase?: string): void;
  setNestedValue<T>(key: string, nestedKey: string, value: T, passphrase?: string): void;
  get<T>(key: string, passphrase?: string): T | null;
  getNestedValue<T>(key: string, nestedKeyPath: string, passphrase?: string): T | null;
  remove(key: string): void;

  setOrUpdateNestedValue<T>(key: string, nestedKeyPath: string, value: T, passphrase?: string): void;
}

function textToChars(text: string) {
  return text.split('').map((c: string) => c.charCodeAt(0));
}
function byteHex(n: any) {
  return ('0' + Number(n).toString(16)).slice(-2);
}
function applySaltToChar(code: any, salt: string) {
  return textToChars(salt).reduce((a: number, b: number) => a ^ b, code);
}

export function encrypt(salt: any, text: string) {
  return text
    .split('')
    .map(textToChars)
    .map((code: any) => applySaltToChar(code, salt))
    .map(byteHex)
    .join('');
}

function decrypt(salt: string, encoded: string) {
  // @ts-expect-error match function can't be null or undefined
  return encoded
    .match(/.{1,2}/g)
    .map((hex: string) => parseInt(hex, 16))
    .map((code: any) => applySaltToChar(code, salt))
    .map((charCode: number) => String.fromCharCode(charCode))
    .join('');
}

const storage: Storage = (() => ({
  set<T>(key: string, value: T, passphrase?: string): void {
    try {
      if (passphrase) {
        const serializedData = JSON.stringify(value);
        const encryptedData = encrypt(passphrase, serializedData);
        localStorage.setItem(key, encryptedData);
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      errorHandler(`Error while setting "${key}" in localStorage: ${error}`);
    }
  },

  setNestedValue<T>(key: string, nestedKey: string, value: T, passphrase?: string): void {
    let storedData = this.get<any>(key, passphrase) || {};

    if (typeof storedData !== 'object') {
      storedData = {};
    }

    storedData[nestedKey] = value;
    this.set(key, storedData, passphrase);
  },

  setOrUpdateNestedValue<T>(key: string, nestedKeyPath: string, value: T, passphrase?: string): void {
    let storedData = this.get<any>(key, passphrase) || {};

    if (typeof storedData !== 'object') {
      storedData = {};
    }

    const nestedKeys = nestedKeyPath.split('.');
    let nestedValue: any = storedData;

    for (let i = 0; i < nestedKeys.length - 1; i++) {
      const nestedKey = nestedKeys[i];

      if (!nestedValue.hasOwnProperty(nestedKey)) {
        nestedValue[nestedKey] = {};
      }

      nestedValue = nestedValue[nestedKey];
    }

    nestedValue[nestedKeys[nestedKeys.length - 1]] = value;
    this.set(key, storedData, passphrase);
  },

  get<T>(key: string, passphrase?: string): T | null {
    try {
      const storedData = localStorage.getItem(key);
      if (!storedData) return null;

      if (passphrase) {
        const decryptedData = decrypt(passphrase, storedData);
        return JSON.parse(decryptedData);
      } else {
        return JSON.parse(storedData);
      }
    } catch (error) {
      errorHandler(`Error while getting "${key}" from localStorage: ${error}`);
      return null;
    }
  },

  getNestedValue<T>(key: string, nestedKeyPath: string, passphrase?: string): T | null {
    const storedData = this.get<any>(key, passphrase);

    if (storedData !== null && typeof storedData === 'object') {
      let nestedValue: any = storedData;

      if (nestedValue.hasOwnProperty(nestedKeyPath)) {
        nestedValue = nestedValue[nestedKeyPath];
      } else {
        return null;
      }

      return nestedValue;
    }

    return null;
  },

  remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      errorHandler(`Error while removing "${key}" from localStorage: ${error}`);
    }
  }
}))();

export default storage;
