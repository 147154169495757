import VueRouteMiddleware from 'vue-route-middleware';
import { createRouter, createWebHistory } from 'vue-router';

import breadcrumbs from '@/components/breadcrumbs/breadcrumbsGenerator';

import i18n from '@/i18n';

import routes from './routes';

const { t } = i18n.global;

export const service = breadcrumbs.create();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

router.beforeEach(VueRouteMiddleware());

export default router;
