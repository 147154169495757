//

export function openUrl(href: string, newTab = false) {
  const a = document.createElement('a');
  a.href = href;

  if (newTab) {
    a.target = '_blank';
  }
  a.click();
}
