import { Ref, onMounted, ref } from 'vue';

interface UseIcons {
  setPath: (path: string) => void;
  icons: Ref<Record<string, Record<string, string>> | undefined>;
}

export function useIcons(path: string) {
  const allIcons = import.meta.glob<Record<string, string>>(`@/assets/icons/**/*.svg`, {
    eager: true
  });

  function icons(name: string) {
    const data = allIcons[`/src/assets/icons/${path}/${name}`];

    if (!data) {
      console.trace(`Icon not found /src/assets/icons/${path}/${name}`);
      return '';
    }

    return data.default;
  }

  return {
    icons
  };
}

export default useIcons;
