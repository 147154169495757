<template>
  <div class="fixed inset-0 flex flex-col items-center justify-end px-4 py-6 pointer-events-none sm:p-6 sm:flex-col sm:items-end sm:justify-start transition-all duration-300 z-40">
    <transition v-for="notification in notifications" :key="notification.id" name="fade">
      <Notification :notification="notification" class="z-40" @dismiss="dismissNotification" />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import Notification from '@/components/notifications/Notification.vue';

import store from '@/store';

const notifications = computed(() => store.getters.notification.getNotifications);

function dismissNotification(id: number) {
  store.commit.notification.dismissNotification(id);
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
