import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import i18n from '@/i18n';
import store from '@/store';

import { middlewareLogger } from '../Logger';

const t = i18n.global.t;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (to: RouteLocation, from: RouteLocation, next: Next) => {
  if (store.state.auth.isLoading) {
    //console.warn("User still loading, unsure flag set to true");
    store.commit.auth.setUnsure(true);
  }
  if (!store.getters.auth.isAuthenticated) {
    if (!store.state.auth.unsure) {
      store.dispatch.notification.setError(t('router.errors.login'));
    }
    middlewareLogger('auth');
    next({ name: 'login', query: { next: to.fullPath } });
    return false;
  }
};
