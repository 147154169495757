<template>
  <Modal v-if="paywall.show" v-slot="{ closeModal }" v-model:show="paywall.show" size="lg">
    <h3 id="modal-headline" class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-400">
      <slot name="title">
        {{ title }}
      </slot>
    </h3>
    <div class="mt-2">
      <p class="text-sm text-gray-500">
        <slot name="description">
          {{ description }}
        </slot>
      </p>
    </div>

    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <VastgoedButton
        v-if="showSubscriptionButton"
        :text="$t('components.payWallModal.activate')"
        :color="ButtonColor.GREEN"
        :size="ButtonSize.MD"
        @click.prevent="navigateSubscriptions"
      />
      <VastgoedButton :text="$t('components.payWallModal.cancel')" :color="ButtonColor.WHITE" :size="ButtonSize.MD" class="mx-3" @click="closeModal" />
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import i18n from '@/i18n';
import router from '@/router';
import Modal from '@/wrappers/Modal.vue';

import { PaymentRequiredReason, paywall } from './paywall';

const { t } = i18n.global;

const titleMap = ref<Record<PaymentRequiredReason, string>>({
  [PaymentRequiredReason.trialExpired]: t('components.payWallModal.trialExpired.title'),
  [PaymentRequiredReason.managementHasNoSubscription]: t('components.payWallModal.managementNoSubscription.title'),
  [PaymentRequiredReason.subscriptionRequired]: t('components.payWallModal.subscriptionRequired.title'),
  [PaymentRequiredReason.insufficientFunds]: t('components.payWallModal.insufficientFunds.title')
});

const title = computed(() => (paywall.value.reason ? titleMap.value[paywall.value.reason] : 'Unknown reason for 402: ' + paywall.value.reason));

const descriptionMap = ref<Partial<Record<PaymentRequiredReason, string>>>({
  [PaymentRequiredReason.trialExpired]: t('components.payWallModal.trialExpired.description'),
  [PaymentRequiredReason.managementHasNoSubscription]: t('components.payWallModal.managementNoSubscription.description'),
  [PaymentRequiredReason.subscriptionRequired]: t('components.payWallModal.subscriptionRequired.description'),
  [PaymentRequiredReason.insufficientFunds]: t('components.payWallModal.insufficientFunds.description')
});

const description = computed(() => (paywall.value.reason ? descriptionMap.value[paywall.value.reason] : 'Unknown reason for 402: ' + paywall.value.reason));

const showSubscriptionButton = computed<boolean>(() => paywall.value.reason !== PaymentRequiredReason.managementHasNoSubscription);

function navigateSubscriptions() {
  paywall.value.show = false;
  router.push({ name: 'settings.subscriptions' });
}
</script>
