import { LanguageKey } from '@/helpers/LocaleHelper';
import { isCompanyDomain, isManagementDomain, isServiceDomain } from '@/helpers/SubdomainHelper';
import { roleIdToName } from '@/helpers/api/RoleableHelper';
import { UserRoleableObject } from '@/helpers/api/companyEnviorment/UserHelper';

import store from '@/store';

import { RepairApiData } from './RepairInternal';
import { Roleable, RoleableApiData } from './Rolables';
import { renderUserRole } from './UserInvite';

export type UserApiData = {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string;
  userRole?: UserRole;
  repair?: RepairApiData | null;
  locale: LanguageKey;
  dark_mode: boolean;
  color_hue: number;
  has_dummy_email?: boolean;
  roleables?: UserRoleableObject[] & RoleableApiData[];
  uses_2fa: boolean;
  accepted_terms: boolean;
  can_detach_roles: boolean;
  can_edit_roles: boolean;
  two_factor_confirmed: boolean;
};

export type UserRole =
  | 'tenant'
  | 'owner'
  | 'administrator'
  | 'manager'
  | 'employee'
  | 'service_owner'
  | 'service_employee'
  | 'handyman'
  | 'management_administrator'
  | 'management_employee'
  | 'service_mechanic'
  | 'maintenance_employee'
  | 'UNKNOWN';

export class User {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string;
  locale: LanguageKey;
  dark_mode: boolean;
  color_hue: number;
  repair?: RepairApiData | null;
  externalRoleables: UserRoleableObject[];
  has_dummy_email: boolean;

  constructor(params: UserApiData) {
    this.id = params.id;
    this.name = params.name;
    // eslint-disable @typescript-eslint/naming-convention
    this.first_name = params.first_name;
    this.has_dummy_email = params.has_dummy_email ?? false;

    // eslint-disable @typescript-eslint/naming-convention
    this.last_name = params.last_name;
    this.email = params.email;
    this.avatar = params.avatar;
    this.locale = params.locale;
    this.color_hue = params.color_hue;
    this.dark_mode = params.dark_mode;
    this.repair = params.repair;
    this.externalRoleables = params.roleables ?? [];
  }

  get prefersDarkMode(): boolean {
    return this.dark_mode;
  }

  get userRolesString(): string {
    const roleNames = this.externalRoleables.map((roleable) => roleable.role.name);

    return roleNames.map(renderUserRole).join(', ');
  }
}

export class AuthUser extends User {
  private _selectedRoleable: Roleable | undefined;
  roleables: Roleable[];
  activeRoleables: Roleable[];
  uses_2fa: boolean;
  two_factor_confirmed: boolean;

  get role(): UserRole {
    if (!this.selectedRoleable) {
      return 'UNKNOWN';
      // throw new Error("Could not get role enum; Roleable not (yet) selected");
    }
    return roleIdToName(this.selectedRoleable.role_id);
  }

  get selectedRoleable(): Roleable | undefined {
    return this._selectedRoleable;
  }

  set selectedRoleable(value: Roleable | undefined) {
    this._selectedRoleable = value;

    // refresh company data in company store
    if (isServiceDomain()) {
      store.dispatch.company.getPublicServiceCompanyData(this.selectedRoleable);
    } else if (isManagementDomain()) {
      store.dispatch.company.getPublicManagementCompanyData(this.selectedRoleable);
    }
  }

  get hasEditRights(): boolean {
    return this.activeRoleables.some((roleable) => roleable.hasEditRights);
  }

  get isReadOnly(): boolean {
    return !this.hasEditRights;
  }

  constructor(params: UserApiData, roleables: Roleable[], activeRoleables: Roleable[]) {
    super(params);

    this.uses_2fa = params.uses_2fa;
    this.two_factor_confirmed = params.two_factor_confirmed;

    this.roleables = roleables;
    this.activeRoleables = activeRoleables;

    if (activeRoleables.length === 1) {
      this.selectedRoleable = activeRoleables[0];
    }
    // If on company subdomain, select first role that applies to this domain.
    else if (isCompanyDomain() && activeRoleables.length > 1) {
      this.selectedRoleable = activeRoleables[0];
    }
  }
}

export default User;
