export const MANAGEMENT_DOMAIN = 'beheer';
export const SERVICE_SUBDOMAIN = 'service';

export const CURRENT_DOMAIN = window.location.hostname.split('.')[0];
export const CURRENT_HOST = window.location.hostname;

export function isManagementDomain(subdomain?: string): boolean {
  if (!subdomain) {
    subdomain = CURRENT_DOMAIN;
  }
  return subdomain === MANAGEMENT_DOMAIN;
}

export function isServiceDomain(subdomain?: string): boolean {
  if (!subdomain) {
    subdomain = CURRENT_DOMAIN;
  }
  return subdomain === SERVICE_SUBDOMAIN;
}

export function isCompanyDomain(subdomain?: string): boolean {
  if (!subdomain) {
    subdomain = CURRENT_DOMAIN;
  }
  return !isManagementDomain(subdomain) && !isServiceDomain(subdomain);
}
