import { App, DirectiveBinding, RendererElement } from 'vue';

import { addEventListener } from '@/helpers/javascript/AddEventListener';

const clickOutsideDirective = {
  mounted(el: RendererElement, binding: DirectiveBinding<(event: MouseEvent, el: RendererElement) => void>) {
    if (typeof binding.value !== 'function') {
      throw new Error('Please attach a callback in the form of: (event: MouseEvent, el: RendererElement) => void to the v-click-outside directive');
    }
    el.removeClickOutsideEventListenerCallback = addEventListener(document.body, 'click', (event) => {
      if (!el.contains(event.target)) {
        binding.value(event, el);
      }
    });
  },
  unmounted(el: RendererElement) {
    el.removeClickOutsideEventListenerCallback();
  }
};

export default {
  install: (app: App) => {
    app.directive('click-outside', clickOutsideDirective);
  }
};
