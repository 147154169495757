import { ref } from 'vue';

export enum PaymentRequiredReason {
  trialExpired = 'trial_expired',
  managementHasNoSubscription = 'management_has_no_subscription',
  subscriptionRequired = 'subscription_required',
  insufficientFunds = 'insufficient_funds'
}

export interface PaywallState {
  showPaywall: boolean;
  paywallReason: PaymentRequiredReason | undefined;
}

interface Paywall {
  show: boolean;
  reason?: PaymentRequiredReason;
}

export const paywall = ref<Paywall>({
  show: false,
  reason: undefined
});
