import { LocationQueryValue } from 'vue-router';

function getFirstQueryValue(queryValue: string | LocationQueryValue[] | null): LocationQueryValue {
  if (Array.isArray(queryValue)) {
    return queryValue[0];
  }
  return queryValue;
}

export { getFirstQueryValue };
