// create a new date, if an input is provided. Return undefined otherwise
export function newDateNull(input: Date | string | undefined | null): Date | undefined {
  if (!input) {
    return undefined;
  }
  return new Date(input);
}

export function isSameMonth(date1: Date, date2: Date): boolean {
  return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export function isSameDay(date1: Date, date2: Date): boolean {
  return isSameMonth(date1, date2) && date1.getDate() === date2.getDate();
}

export function isBetween({ d1, d2 }: { d1: Date; d2: Date }, target: Date): boolean {
  const targetTime = target.getTime();
  return (d1.getTime() <= targetTime && targetTime <= d2.getTime()) || (d2.getTime() <= targetTime && targetTime <= d1.getTime());
}

// relative to now
export function isCurrentMonth(date: Date): boolean {
  const now = new Date();

  return isSameMonth(now, date);
}

export function isInFuture(date?: Date | string | null): boolean {
  if (!date) {
    return false;
  }

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const now = new Date();

  return date.getTime() > now.getTime();
}

export function isInPast(date?: Date | string | null): boolean {
  if (!date) {
    return false;
  }

  return !isInFuture(date);
}

// sorting
type ConvertToDate<T> = (object: T) => Date;

export const byDateOldToNew =
  <T>(dateConverter: ConvertToDate<T>) =>
  (a: T, b: T) => {
    return dateConverter(a).getTime() - dateConverter(b).getTime();
  };

export const byDateNewToOld =
  <T>(dateConverter: ConvertToDate<T>) =>
  (a: T, b: T) => {
    return dateConverter(b).getTime() - dateConverter(a).getTime();
  };

export function firstOrLastofPastOrFurtureMonths(firstOfMonth = false, plusMinus: '-' | '+', months: number) {
  const today = new Date();
  let toMonth = 0;

  if (plusMinus === '-') {
    toMonth = today.setMonth(today.getMonth() - months);
  } else if (plusMinus === '+') {
    toMonth = today.setMonth(today.getMonth() + months);
  }

  const toConvert = new Date(toMonth);

  if (months === new Date().getMonth()) {
    return toConvert;
  }

  // last minus does the submonths
  const lastMonth = toConvert.getMonth() === 0 ? 11 : toConvert.getMonth();
  const year = lastMonth === 0 ? toConvert.getFullYear() - 1 : toConvert.getFullYear();

  return firstOfMonth ? new Date(year, lastMonth, 1, 0) : new Date(year, lastMonth + 1, 0);
}
