export enum NotificationType {
  ERROR = 'error',
  WARN = 'warning',
  SUCC = 'success',
  INFO = 'info'
}

export interface Notification {
  type: NotificationType;
  title?: string;
  text: string;
  dismissed: boolean;
  id: number;
}

export default Notification;
