import { PublicCompanyData } from '@/store/modules/company';

import { PillColor } from '@/components/table/helpers/types';

import { MediaItem } from '@/models/Media';
import Property, { PropertyApiData, PropertyApiSingleData, PropertySingle } from '@/models/Property';

import { Installation, InstallationApiData } from '@/helpers/api/companyEnviorment/InstallationHelper';
import { ServiceCompany } from '@/helpers/api/companyEnviorment/RepairHelper';
import { GetServiceClient } from '@/helpers/api/serviceEnviorment/ServiceClientHelper';

import i18n from '@/i18n';

import { ActivityApiData } from './Activities';
import { HasNotes, Note, Noteable, NoteableModel } from './Noteable';
import { RepairSeverity } from './Repair';
import User, { UserApiData } from './User';

const $t = i18n.global.t;

export function renderRepairReason(reason: RepairReason) {
  return $t(`models.repair.reason.${reason}`);
}

export const AllRepairReasons = ['ignorance_renter', 'break_in', 'deferred_maintenance', 'abuse', 'bad_assembly', 'bad_quality', 'other'] as const;

export type RepairReason = (typeof AllRepairReasons)[number];

export type RepairAvailability = {
  id: number;
  day?: Date | string;
  window: 'midday' | 'evening' | 'morning';
};

export type RepairQuestion = {
  description: string;
  id: number;
  media: null;
  media_type: null;
  next_question_id: number | null;
  prev_question_id: number;
  question: string;
  repair_action_type: number;
  repair_type: { id: number; name: string; image: null };
  repair_type_id: number;
  severity: RepairSeverity;
};

const RepairStatuses = ['created', 'accepted', 'en_route', 'in_progress', 'done', 'problem', 'closed'] as const;

export type RepairStatus = (typeof RepairStatuses)[number];

export function renderRepairStatus(status: RepairStatus | 'all'): string {
  return $t(`models.repair.status.${status}`);
}

export const repairOptions = RepairStatuses.map((name) => ({
  key: name,
  text: renderRepairStatus(name)
}));

export const repairStatusColorMap = new Map<RepairStatus, PillColor>([
  ['created', 'gray'],
  ['accepted', 'blue'],
  ['en_route', 'indigo'],
  ['in_progress', 'yellow'],
  ['done', 'green'],
  ['problem', 'red'],
  ['closed', 'black']
]);

export type Handyman = User;
export type ServiceEmployee = User;

export type RepairApiSingleData = HasNotes & {
  availability: RepairAvailability[];
  comment: string;
  created_at: string;
  due_date: string;
  expected_duration: number;
  email: string;
  id: number;
  media: MediaItem[];
  name: string;
  phone: string;
  property: PropertyApiSingleData;
  repair_question: RepairQuestion;
  task: boolean;
  ticket: string;

  worker_id: number | null;
  handyman: Handyman | null;
  service_company: ServiceCompany | null;
  company?: PublicCompanyData;
  service_client?: GetServiceClient;
  service_worker?: ServiceCompany;
  activities?: ActivityApiData[];
  mandate?: number;
  status: RepairStatus;
  severity: RepairSeverity;
  reason?: RepairReason;
  custom_reason?: string;
  repair_hours?: RepairHourData[];
  installation_id: string | number;
  responsible?: User;
};

export type RepairServiceClient = {
  id: number;
  name: string;
  street: string;
  postcode: string;
  town: string;
  kvk_number: string;
  btw_number: string;
};

export type RepairApiData = {
  availability?: RepairAvailability[];
  comment: string;
  created_at: string;
  due_date: string;
  expected_duration: number;
  email: string;
  id: number;
  name: string;
  phone: string;
  property?: PropertyApiData;
  repair_question?: RepairQuestion;
  task: boolean;
  ticket: string;
  status: RepairStatus;
  severity: RepairSeverity;
  service_client?: RepairServiceClient;
  company?: PublicCompanyData;
  worker_id: number | null;
  handyman?: UserApiData;
  installation?: InstallationApiData;
  responsible_user_id?: number | null;
};

export type RepairHourData = {
  id: number;
  user?: User;
  duration: number;
  worked_on: Date;
};

// class for repair index
export class Repair {
  availability?: RepairAvailability[];
  comment: string;
  created_at: Date;
  due_date?: Date;
  expected_duration: number;
  email: string;
  id: number;
  name: string;
  phone: string;
  property?: Property;
  repair_question?: RepairQuestion;
  task: boolean;
  ticket: string;
  status: RepairStatus;
  _severity: RepairSeverity;
  service_client?: RepairServiceClient;
  company?: PublicCompanyData;
  worker_id: number | null;
  handyman?: Handyman;
  installation?: InstallationApiData;
  responsible_user_id?: number | null;

  get propertyAddress(): string {
    return this.property?.full_address ?? '';
  }

  get ticketKind(): string {
    return this.repair_question?.repair_type.name ?? '';
  }

  get severity(): RepairSeverity {
    return this._severity ?? this.repair_question?.severity ?? 1;
  }

  constructor(params: RepairApiData) {
    this.availability = params.availability;
    this.comment = params.comment;
    // eslint-disable @typescript-eslint/naming-convention
    this.created_at = new Date(params.created_at);
    this.expected_duration = params.expected_duration;
    this.email = params.email;
    this.id = params.id;
    this.name = params.name;
    this.phone = params.phone;
    // eslint-disable @typescript-eslint/naming-convention
    this.repair_question = params.repair_question;
    this.task = params.task;
    this.ticket = params.ticket;
    this.status = params.status;
    this._severity = params.severity;
    this.service_client = params.service_client;
    this.company = params.company;
    this.worker_id = params.worker_id;
    this.installation = params.installation;
    this.responsible_user_id = params.responsible_user_id;

    const { property, handyman, due_date } = params;
    this.property = property ? new Property(property) : undefined;
    this.handyman = handyman ? new User(handyman) : undefined;
    this.due_date = due_date ? new Date(due_date) : undefined;
  }
}

export class RepairSingle implements Noteable {
  availability: RepairAvailability[];
  comment: string;
  created_at: string;
  due_date: string;
  expected_duration: number;
  email: string;
  id: number;
  media?: MediaItem[];
  name: string;
  phone: string;
  property: PropertySingle | undefined;
  repair_question: RepairQuestion;
  task: boolean;
  ticket: string;
  activities: ActivityApiData[];
  mandate?: number;
  status: RepairStatus;
  _severity: RepairSeverity;
  reason?: RepairReason;
  custom_reason?: string;
  service_worker?: ServiceCompany;

  installation_id: string | number;

  worker_id: number | null;
  handyman: Handyman | null;
  service_company: ServiceCompany | null;
  service_client?: GetServiceClient;
  company?: PublicCompanyData;

  notes: Note[];
  note_type: NoteableModel;
  responsible?: User;

  repair_hours: RepairHourData[];

  get propertyAddress(): string {
    return this.property?.full_address ?? '';
  }

  get ticketKind(): string {
    return this.repair_question?.repair_type.name ?? '';
  }

  get severity(): RepairSeverity {
    return this._severity ?? this.repair_question?.severity ?? 1;
  }

  constructor(params: RepairApiSingleData) {
    this.note_type = 'repair';

    this.availability = params.availability;
    this.comment = params.comment;
    this.created_at = params.created_at;
    this.due_date = params.due_date;
    this.expected_duration = params.expected_duration;
    this.email = params.email;
    this.id = params.id;
    this.media = params.media;
    this.name = params.name;
    this.phone = params.phone;
    this.repair_question = params.repair_question;
    this.task = params.task;
    this.ticket = params.ticket;
    this.status = params.status;
    this._severity = params.severity;
    this.reason = params.reason;
    this.custom_reason = params.custom_reason;
    this.service_worker = params.service_worker;

    this.handyman = params.handyman;
    this.service_company = params.service_company;
    this.company = params.company;
    this.service_client = params.service_client;
    this.worker_id = params.worker_id;
    this.responsible = params.responsible;

    this.notes = params.notes;
    this.installation_id = params.installation_id;

    const { property, activities } = params;
    this.property = property ? new PropertySingle(property) : undefined;
    this.activities = activities ?? [];
    this.mandate = params.mandate;
    this.repair_hours = params.repair_hours ?? [];
  }
}
