<template>
  <a
    :href="mainDomain"
    target="_blank"
    class="flex justify-center items-center h-12 w-12 rounded-full transition duration-100 cursor-pointer"
    :class="{
      'bg-darkblue-500 hover:bg-white dark:bg-gray-100': background === 'blue',
      'bg-white hover:bg-darkblue-500': background === ''
    }"
  >
    <svg class="w-7 mt-1 h-auto" viewBox="0 0 110 89" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Vastgoed.app_beeldmerk" fill-rule="nonzero">
          <path d="M90.9,70 C85.9,70 81.8,74.1 81.8,79.1 C81.8,84.1 85.9,88.2 90.9,88.2 C95.9,88.2 100,84.1 100,79.1 C100,74 95.9,70 90.9,70" id="Shape" fill="#FFBD00"></path>
          <path d="M23.1,0 L66.9,70.1 L77.5,53.1 C78.4,51.6 78.4,49.8 77.5,48.3 L48.7,2.1 C47.8,0.8 46.4,0 44.8,0 L23.1,0 Z" id="Shape" fill="#B4005A"></path>
          <path d="M0.3,2.8 L53.1,87.3 C53.8,88.4 55.5,88.4 56.2,87.3 L66.9,70.1 L23.1,0 L1.8,0 C0.4,0 -0.5,1.6 0.3,2.8 Z" id="Shape" fill="#FF2E12"></path>
          <path d="M107.5,0 L74.3,0 C72.9,0 72,1.6 72.8,2.8 L89.4,29.3 C90.1,30.4 91.8,30.4 92.5,29.3 L109,2.8 C109.8,1.6 108.9,0 107.5,0 Z" id="Shape" fill="#0091F5"></path>
        </g>
      </g>
    </svg>
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    background: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mainDomain: import.meta.env.VITE_MAIN_DOMAIN
    };
  }
});
</script>
