import { Plugin } from 'vue';

import Button from '@/components/Button.vue';

export const ButtonColor = {
  BLUE: 'blue',
  LIGHTBLUE: 'lightblue',
  DARKBLUE: 'darkblue',
  OUTLINEBLUE: 'blue-outline',
  GREEN: 'green',
  RED: 'red',
  PINK: 'pink',
  YELLOW: 'yellow',
  GRAY: 'gray',
  WHITE: 'white'
} as const;

export const ButtonSize = {
  XS: 'xs',
  SM: 'sm',
  SMF: 'smf',
  MD: 'md',
  MDF: 'mdf',
  LG: 'lg',
  LGF: 'lgf'
} as const;

declare module 'vue' {
  interface ComponentCustomProperties {
    ButtonColor: typeof ButtonColor;
    ButtonSize: typeof ButtonSize;
  }
}

export const VastgoedButton: Plugin = {
  install(app) {
    app.config.globalProperties.ButtonColor = ButtonColor;
    app.config.globalProperties.ButtonSize = ButtonSize;
    app.component('VastgoedButton', Button);
  }
};

export default VastgoedButton;
