import { MANAGEMENT_DOMAIN, SERVICE_SUBDOMAIN } from '@/helpers/SubdomainHelper';

import { KeysOf } from './Property';

export type RoleablesApiResponse = {
  roleables: RoleableApiData[];
};
export const ROLEABLE_TYPE_COMPANY = 'App\\Models\\Company';
export type RoleableTypeCompany = typeof ROLEABLE_TYPE_COMPANY;
export const ROLEABLE_TYPE_SERVICE_COMPANY = 'App\\Models\\ServiceCompany';
export type RoleableTypeServiceCompany = typeof ROLEABLE_TYPE_SERVICE_COMPANY;
export const ROLEABLE_TYPE_MANAGEMENT_COMPANY = 'App\\Models\\ManagementCompany';
export type RoleableTypeManagementCompany = typeof ROLEABLE_TYPE_MANAGEMENT_COMPANY;

export type RoleableType = RoleableTypeCompany | RoleableTypeServiceCompany | RoleableTypeManagementCompany;

export type RoleableApiData = {
  name: string;
  icon: string | null;
  domain: string;
  role_id: number;
  roleable_id: number;
  roleable_type: RoleableType;
  company_user_roles_read_only: boolean;
};

export class Roleable implements KeysOf<RoleableApiData> {
  name: string;
  icon: string | null;
  _domain: string;
  role_id: number;
  roleable_id: number;
  roleable_type: RoleableType;
  company_user_roles_read_only: boolean;

  get domain(): string {
    if (this.isManagement()) {
      return MANAGEMENT_DOMAIN;
    } else if (this.isService()) {
      return SERVICE_SUBDOMAIN;
    } else {
      return this._domain;
    }
  }

  get hasEditRights(): boolean {
    return !this.read_only;
  }

  get read_only(): boolean {
    return this.company_user_roles_read_only;
  }

  isManagement(): boolean {
    return this.roleable_type === ROLEABLE_TYPE_MANAGEMENT_COMPANY;
  }

  isService(): boolean {
    return this.roleable_type === ROLEABLE_TYPE_SERVICE_COMPANY;
  }

  constructor(params: RoleableApiData) {
    this.name = params.name;
    this.icon = params.icon;
    this._domain = params.domain;
    this.role_id = params.role_id;
    this.roleable_id = params.roleable_id;
    this.roleable_type = params.roleable_type;
    this.company_user_roles_read_only = params.company_user_roles_read_only;
  }
}
