import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import { middlewareLogger } from '@/router/Logger';

import { hasTenantRole } from '@/helpers/api/RoleableHelper';

import i18n from '@/i18n';
import store from '@/store';

const t = i18n.global.t;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (to: RouteLocation, from: RouteLocation, next: Next) => {
  const userRole = store.getters.auth.userRole;
  if (!hasTenantRole(userRole)) {
    store.dispatch.notification.setError(t('router.errors.userRole', { userRole }));
    middlewareLogger('role:tenant: ', userRole);
    next({ name: 'dashboard' });
    return false;
  }
};
