import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import store from '@/store';

import { middlewareLogger } from '../Logger';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (to: RouteLocation, from: RouteLocation, next: Next) => {
  if (!store.getters.auth.isAuthenticated) {
    throw new Error('Cannot use hasRoleSelected middleware without auth middleware!');
  }
  if (!store.state.auth.user?.selectedRoleable) {
    middlewareLogger('hasRoleSelected');
    next({ name: 'role.select', query: { next: to.fullPath } });
    return false;
  }
};
