import { sortToQuery } from '@/components/table/components/sorting/types';

import { APIParams } from '@/models/Pagination';

import { wrapInArray } from '../javascript/ArrayHelper';

export function constructQuery(params: APIParams = {}, url = ''): string {
  const queries: string[] = [];

  if (params.page) {
    queries.push(`page=${params.page}`);
  }

  if (params.sort?.key) {
    const sortQuery = sortToQuery(params.sort);
    if (sortQuery !== undefined) {
      queries.push(sortQuery);
    }
  } else if (params.defaultSort) {
    const query =
      'sort=' +
      params.defaultSort
        .map((sortSetting) => sortToQuery(sortSetting, false))
        .filter((x) => !!x)
        .join(',');

    queries.push(query);
  }

  if (params.search) {
    if (params.search !== '') {
      // hack
      if (params.searchCustomKey) {
        queries.push(`filter[${params.searchCustomKey}]=${params.search}`);
      } else if (params.searchInFilter === undefined) {
        queries.push(`filter[search]=${params.search}`);
        queries.push(`search=${params.search}`);
      } else if (params.searchInFilter) {
        queries.push(`filter[search]=${params.search}`);
      } else {
        queries.push(`search=${params.search}`);
      }
    }
  }

  if (params.filters) {
    for (const [key, value] of Object.entries(params.filters)) {
      const valueArray = wrapInArray(value);

      const removedEmpty = valueArray.filter((str) => !!str);

      if (removedEmpty.length !== 0) {
        queries.push(`filter[${key}]=` + removedEmpty.join(','));
      }
    }
  }

  if (params.pagination) {
    queries.push(`paginate=${params.pagination}`);
  }

  if (params.include) {
    const includes = wrapInArray(params.include);

    queries.push(`include=${includes.join(',')}`);
  }

  if (params.custom) {
    const customs = wrapInArray(params.custom);
    customs.forEach((c) => queries.push(c));
  }

  const queryString = queries.length !== 0 ? '?' + queries.join('&') : '';

  return url + queryString;
}
