import { Country } from '@/store/modules/country';

import { Agreement, AgreementApiData } from '@/models/Agreement';
import { HasMedia, MediaItem } from '@/models/Media';

import { GenderKeys } from '@/helpers/GenderHelper';
import { LanguageKey } from '@/helpers/LocaleHelper';
import { ExactInternal } from '@/helpers/api/companyEnviorment/ExactLinkTypes';

import { ContactForm as Contact } from './ContactForm';
import { IntakeContact } from './Intake';
import { HasNotes, Note, Noteable, NoteableModel } from './Noteable';
import User from './User';

export type TenantRole = 'resident' | 'cotenant';

export type TenantSingleApiData = HasNotes & {
  id: number;
  company_name: string | null;
  phone: string;
  reference: string | null;
  date_of_birth: string;
  place_of_birth: string;
  created_at: string;
  user: User;

  agreements: AgreementApiData[];
  media: MediaItem[];
  contacts?: IntakeContact[];
  country: Country | null;
  gender: GenderKeys;
  role?: TenantRole;
  locale: LanguageKey;

  user_active: boolean;

  iban?: string;
  bic?: string;
  invoice_email?: string;
  invoice_street?: string;
  invoice_postcode?: string;
  invoice_town?: string;
  invoice_house_number?: string;
  invoice_house_number_addition?: string;
  balance: number;

  has_balance_transactions: boolean;
  money_owed: number;

  crm_account?: ExactInternal | null;
  bizcuit_identifier?: string;
  belongs_to_multiple_companies: boolean;

  kvk_number?: string | null;
  btw_number?: string | null;
  board_member?: string | null;
  board_member_other?: string | null;
};

export type TenantApiData = {
  id: number;
  user_id: number;
  company_name: string | null;
  phone: string;
  reference: string | null;
  date_of_birth: string;
  place_of_birth: string;
  created_at: string;
  first_name: string;
  last_name: string;
  email: string;
  avatar: string | null;
  has_dummy_email: boolean;
  user_active: boolean;
  role?: TenantRole;
  crmAccount?: ExactInternal | null;
  tenant_id?: number | null;
  iban?: string;
  bic?: string;
};

export type StoreTenantForm = {
  first_name: string;
  last_name: string;
  is_company: boolean;
  company_name: string;
  date_of_birth?: Date | null;
  email: string;
  has_dummy_email: boolean;
  phone: string;
  reference: string;
  gender: string;
  country_id: string;

  contacts: Contact[];

  place_of_birth: string;
  files: File[];
  current_address: string;

  iban?: string;
  bic?: string;
  invoice_email?: string;
  invoice_street: string;
  invoice_postcode: string;
  invoice_town: string;
  invoice_house_number: string;
  invoice_house_number_addition: string;

  send_email: boolean;
  locale: LanguageKey;

  kvk_number?: string;
  btw_number?: string;

  board_member?: string;
  board_member_other?: string;

  exact_id: string | undefined;
};

export type StoreTenantWithIntakeForm = {
  email: string;
  first_name: string;
  last_name: string;
  company_name: string;
  date_of_birth: Date | null;
  place_of_birth: string;
  country_id: string;
  gender: string;
  contacts: Contact[];
  up_files: File[];
  phone: string;
  current_address: string;
  iban: string | null;
  bic: string | null;

  generalTerms: boolean;
  privacyPolicy: boolean;
  dataProcessing: boolean;
};

export type UpdateTenantForm = Partial<{
  email: string;
  user: Pick<User, 'first_name' | 'last_name'>;
  first_name: string;
  last_name: string;
  company_name: string | null;
  phone: string | null;
  date_of_birth: Date;
  place_of_birth: string | null;
  country_id: string;
  gender: string;
  send_email: boolean;
  locale: LanguageKey;
  kvk_number: string | null;
  btw_number: string | null;
  board_member: string | null;
  board_member_other: string | null;

  iban: string | null;
  bic: string | null;
  invoice_email: string | null;
  invoice_street: string | null;
  invoice_house_number: string | null;
  invoice_house_number_addition: string | null;
  invoice_postcode: string | null;
  invoice_town: string | null;
  reference: string | null;
}>;

export type UpdateTenantWithExistingEmailForm = Partial<{
  email: string;
  send_email: boolean;
}>;

export type CreateNewExactRelationForm = Partial<{
  first_name: string;
  last_name: string;
  email: string;
}>;

export class Tenant {
  id: number;
  user_id: number;
  avatar?: string | null;
  first_name: string;
  last_name: string;
  company_name: string | null;
  email: string;
  phone: string;
  reference: string | null;
  date_of_birth: Date;
  place_of_birth: string;
  created_at: string;
  has_dummy_email: boolean;
  user_active: boolean;
  crmAccount?: ExactInternal | null;
  iban?: string;
  bic?: string;

  get is_company(): boolean {
    return this.company_name !== null;
  }

  get full_name() {
    return this.first_name + ' ' + this.last_name;
  }

  constructor(params: TenantApiData) {
    this.id = params.id;
    this.first_name = params.first_name;
    this.last_name = params.last_name;
    this.company_name = params.company_name;
    this.email = params.email;
    this.phone = params.phone;
    this.reference = params.reference;
    this.date_of_birth = new Date(params.date_of_birth);
    this.place_of_birth = params.place_of_birth;
    this.has_dummy_email = params.has_dummy_email;
    this.user_active = params.user_active;
    this.created_at = params.created_at;
    this.user_id = params.user_id;
    this.crmAccount = params.crmAccount;
    this.iban = params.iban;
    this.bic = params.bic;
  }
}

export class TenantSingle implements HasMedia, Noteable {
  notes: Note[];
  note_type: NoteableModel;
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  company_name: string | null;
  email: string;
  phone: string;
  reference: string | null;
  date_of_birth: Date;
  place_of_birth: string;
  created_at: string;
  avatar: string | null;
  agreements: Agreement[];
  media: MediaItem[];
  contacts: IntakeContact[];
  country?: Country;
  gender: GenderKeys;
  user_active: boolean;
  has_dummy_email: boolean;
  role?: TenantRole;
  locale: LanguageKey;

  iban?: string;
  bic?: string;
  invoice_email?: string;
  invoice_street?: string;
  invoice_postcode?: string;
  invoice_town?: string;
  invoice_house_number?: string;
  invoice_house_number_addition?: string;
  balance: number;

  user: User;

  has_balance_transactions: boolean;
  money_owed: number;

  kvk_number?: string | null;
  btw_number?: string | null;
  board_member?: string | null;
  board_member_other?: string | null;

  crm_account: ExactInternal | null | undefined;

  bizcuit_identifier?: string | null;
  belongs_to_multiple_companies: boolean;

  get is_company(): boolean {
    return this.company_name !== null;
  }

  get full_name() {
    return this.first_name + ' ' + this.last_name;
  }

  get invoice_address(): string | undefined {
    if (!this.invoice_street) {
      return undefined;
    }
    return `${this.invoice_street} ${this.invoice_house_number ?? ''}${this.invoice_house_number_addition ?? ''}`;
  }

  constructor(params: TenantSingleApiData) {
    this.note_type = 'tenant';
    this.id = params.id;
    this.user_id = params.user.id;
    this.first_name = params.user.first_name;
    this.last_name = params.user.last_name;
    this.company_name = params.company_name;
    this.email = params.user.email;
    this.phone = params.phone;
    this.reference = params.reference;
    this.created_at = params.created_at;
    this.date_of_birth = new Date(params.date_of_birth);
    this.place_of_birth = params.place_of_birth;
    this.user_active = params.user_active;
    this.has_dummy_email = params.user.has_dummy_email;
    this.avatar = params.user.avatar;
    this.agreements = params.agreements?.map((data) => new Agreement(data));
    this.media = params.media;
    this.contacts = params.contacts ?? [];
    this.country = params.country ?? undefined;
    this.gender = params.gender;
    this.role = params.role;
    this.notes = params.notes ?? [];
    this.locale = params.locale;

    this.iban = params.iban;
    this.bic = params.bic;
    this.invoice_email = params.invoice_email;
    this.invoice_street = params.invoice_street;
    this.invoice_postcode = params.invoice_postcode;
    this.invoice_town = params.invoice_town;
    this.invoice_house_number = params.invoice_house_number;
    this.invoice_house_number_addition = params.invoice_house_number_addition;
    this.balance = params.balance;

    this.user = params.user;

    this.has_balance_transactions = params.has_balance_transactions;
    this.money_owed = params.money_owed;

    this.crm_account = params.crm_account;

    this.bizcuit_identifier = params.bizcuit_identifier;
    this.belongs_to_multiple_companies = params.belongs_to_multiple_companies;

    this.kvk_number = params.kvk_number;
    this.btw_number = params.btw_number;
    this.board_member = params.board_member;
    this.board_member_other = params.board_member_other;
  }
}

export default Tenant;
