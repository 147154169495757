<template>
  <div
    v-full-screen-height
    class="bg-gray-50 bg-cover bg-no-repeat bg-center flex flex-col justify-center px-6 sm:px-6 lg:px-8"
    :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
  >
    <div class="mt-8 sm:mx-auto sm:w-full md:max-w-lg">
      <div class="bg-white py-10 px-6 shadow border border-gray-200 rounded-md sm:px-16 sm:py-16">
        <div class="flex justify-center">
          <div
            class="w-40 h-20 md:w-48 md:h-28 bg-contain bg-no-repeat bg-top"
            :style="{
              backgroundImage: 'url(' + companyLogoUrl + ')'
            }"
            alt=""
          />
        </div>
        <h2 class="heading-2 text-center mt-2">
          <i18n-t scope="global" keypath="viewsManagement.welcome.title" />
        </h2>
        <p class="mt-4 text-center text-sm md:text-base text-gray-600 px-4 md:px-0">
          <i18n-t scope="global" keypath="viewsManagement.welcome.introText" />
        </p>
        <div class="mt-10">
          <VastgoedButton :text="$t('viewsManagement.welcome.login')" :color="ButtonColor.BLUE" :size="ButtonSize.LGF" :to="{ name: 'login' }" icon="lock" />
        </div>
      </div>
      <div class="flex justify-center bottom-0 mx-auto items-center mt-10">
        <LogoIcon />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import LogoIcon from '@/components/static/LogoIcon.vue';

import backgroundSrc from '@/assets/images/background-management.jpg?url';

export default defineComponent({
  name: 'App',
  components: {
    LogoIcon
  },
  data() {
    return {
      mainDomain: import.meta.env.VITE_MAIN_DOMAIN,
      companyLogoUrl: 'https://vastgoed.app/assets/images/mail/logo.png',
      backgroundImage: backgroundSrc
    };
  }
});
</script>

<style></style>
