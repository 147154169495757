import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const idProp =
  (idName = 'id') =>
  (route: RouteLocationNormalized) => {
    const id = +route.params[idName];
    return { ...route.params, [idName]: id };
  };

const property = [
  {
    path: 'properties',
    name: 'am.property.index',
    component: () => import('@/views/maintenance/property/Index.vue')
  },
  {
    path: 'properties/residential',
    name: 'am.property.index.residential',
    component: () => import('@/views/maintenance/property/Index.vue')
  },
  {
    path: 'properties/commercial',
    name: 'am.property.index.commercial',
    component: () => import('@/views/maintenance/property/Index.vue')
  },
  {
    path: 'properties/misc',
    name: 'am.property.index.misc',
    component: () => import('@/views/maintenance/property/Index.vue')
  },
  {
    path: 'property/:id',
    name: 'am.property',
    component: () => import('@/views/maintenance/property/show/Wrapper.vue'),
    props: idProp(),
    children: [
      {
        path: '',
        name: 'am.property.show',
        component: () => import('@/views/maintenance/property/show/General.vue')
      },
      {
        path: 'spaces',
        name: 'am.property.show.spaces.index',
        component: () => import('@/views/maintenance/property/show/space/Index.vue')
      },
      {
        path: 'space/:spaceId',
        name: 'am.property.show.spaces.show',
        component: () => import('@/views/maintenance/property/show/space/Show.vue')
      },
      {
        path: 'maintenance',
        name: 'am.property.show.maintenance.index',
        component: () => import('@/views/maintenance/property/show/maintenance/Maintenance.vue')
      },
      {
        path: 'installations',
        name: 'am.property.show.installation.index',
        component: () => import('@/views/maintenance/property/show/installation/Index.vue')
      },
      {
        path: 'installation/:installationId',
        name: 'am.property.show.installation.show',
        component: () => import('@/views/maintenance/property/show/installation/Show.vue'),
        props: true
      }
    ]
  },
  {
    path: 'properties/inspections',
    name: 'am.property.inspection.index',
    component: () => import('@/views/maintenance/property/inspections/Inspections.vue')
  },
  {
    path: 'properties/map',
    name: 'am.property.map',
    component: () => import('@/views/maintenance/property/map/Map.vue')
  }
];

const maintenance = [
  {
    path: 'maintenance/assignments',
    name: 'am.maintenance.assignment.index',
    component: () => import('@/views/maintenance/maintenance/assignment/Open.vue')
  },
  {
    path: 'maintenance/assignment/:id',
    name: 'am.maintenance.assignment.show',
    component: () => import('@/components/repair/ReportAssignmentShow.vue'),
    props: idProp()
  },
  {
    path: 'maintenance/assignments/closed',
    name: 'am.maintenance.assignment.closed',
    component: () => import('@/views/maintenance/maintenance/assignment/Closed.vue')
  },
  {
    path: 'maintenance/reports',
    name: 'am.maintenance.report.index',
    component: () => import('@/views/maintenance/maintenance/report/Index.vue')
  },
  {
    path: 'maintenance/report/:id',
    name: 'am.maintenance.report.show',
    component: () => import('@/components/repair/ReportAssignmentShow.vue'),
    props: idProp()
  },
  {
    path: 'maintenance/calendar',
    name: 'am.maintenance.calendar',
    component: () => import('@/views/maintenance/Calendar.vue')
  },
  {
    path: 'maintenance/team',
    name: 'am.maintenance.team',
    component: () => import('@/views/maintenance/maintenance/Team.vue')
  },
  {
    path: 'maintenance/services',
    name: 'am.maintenance.services',
    component: () => import('@/views/maintenance/maintenance/services/Index.vue')
  },
  {
    path: 'maintenance/services/:id',
    name: 'am.maintenance.services.show',
    component: () => import('@/views/maintenance/maintenance/services/Show.vue'),
    props: idProp()
  },
  {
    path: 'maintenance/services/invites',
    name: 'am.maintenance.services.invites',
    component: () => import('@/views/maintenance/maintenance/services/Invites.vue')
  },
  {
    path: 'maintenance/map',
    name: 'am.maintenance.map',
    component: () => import('@/views/maintenance/maintenance/Map.vue'),
    meta: { fullscreen: true }
  }
];

const messages = [
  {
    path: 'messages',
    name: 'am.communication.messages.index',
    component: () => import('@/views/maintenance/communication/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => ({ ...route.params, isTenant: false })
  },
  {
    path: 'messages/unread',
    name: 'am.communication.messages.unread',
    component: () => import('@/views/maintenance/communication/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => ({ ...route.params, isTenant: false })
  },
  {
    path: 'messages/archive',
    name: 'am.communication.messages.archive',
    component: () => import('@/views/maintenance/communication/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => ({ ...route.params, isTenant: false })
  },
  {
    path: 'announcements',
    name: 'am.communication.announcements.index',
    component: () => import('@/views/maintenance/communication/announcements/Index.vue')
  },
  {
    path: 'announcements/drafts',
    name: 'am.communication.announcements.drafts',
    component: () => import('@/views/maintenance/communication/announcements/Index.vue')
  }
];

const settings = [
  {
    path: 'settings',
    name: 'am.settings',
    component: () => import('@/views/maintenance/settings/Wrapper.vue'),
    children: [
      {
        path: 'users',
        name: 'am.settings.users',
        component: () => import('@/views/maintenance/settings/Users.vue')
      },
      {
        path: 'checkups',
        name: 'am.settings.checkups',
        component: () => import('@/views/maintenance/settings/Checkups.vue')
      },
      {
        path: 'flows',
        name: 'am.settings.repairflows',
        component: () => import('@/views/maintenance/settings/repairflow/RepairFlows.vue')
      },
      {
        path: 'installations',
        name: 'am.settings.installations',
        component: () => import('@/views/common/settings/Installations.vue')
      }
    ]
  },
  {
    path: 'settings/flows/:id',
    name: 'am.settings.repairflows.show',
    component: () => import('@/views/maintenance/settings/repairflow/RepairFlowView.vue'),
    props: idProp()
  }
];

const misc = [
  {
    path: 'notifications',
    name: 'am.company.notifications',
    component: () => import('@/views/common/Notifications.vue')
  },
  {
    path: 'company/switch',
    name: 'am.company.switch',
    component: () => import('@/views/CompanySwitcher.vue')
  },
  {
    path: 'account',
    name: 'am.account',
    component: () => import('@/views/common/Account.vue')
  },
  {
    path: 'search',
    name: 'am.search',
    component: () => import('@/views/company/Search.vue')
  }
];

export const maintenanceRoutes: RouteRecordRaw[] = [
  {
    path: '/am/dashboard',
    name: 'maintenance.dashboard',
    component: () => import('@/views/maintenance/Dashboard.vue')
  },

  ...property,
  ...maintenance,
  ...messages,
  ...settings,
  ...misc
];
