<template>
  <div v-if="object === undefined && showLoading">
    <slot name="loading">
      <div v-full-screen-height="forceFullHeight" class="">
        <div class="h-full flex flex-wrap content-center justify-center">
          <div class="flex flex-col justify-center items-center">
            <div class="mb-6">
              <LogoIcon />
            </div>
            <div class="loading-bar" />
          </div>
        </div>
      </div>
    </slot>
  </div>
  <div v-else-if="object === null">
    <slot name="404">
      <div class="min-h-full pt-16 pb-12 flex flex-col">
        <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex-shrink-0 flex justify-center">
            <svg class="w-12 h-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path
                fill="#0083dd"
                d="M216.9 96C251.7 96 280 124.3 280 159.1C280 181.8 267.9 202.7 248.3 213.9L215.1 232.4C215.8 245.5 205.1 256 192 256C178.7 256 168 245.3 168 232V218.5C168 209.9 172.6 202 180.1 197.7L224.4 172.3C229.1 169.6 232 164.6 232 159.1C232 150.8 225.2 144 216.9 144H158.6C155.2 144 152.2 146.1 151.1 149.3L150.6 150.6C146.2 163.1 132.5 169.6 119.1 165.2C107.5 160.7 100.9 147 105.4 134.5L105.8 133.3C113.8 110.9 134.9 96 158.6 96L216.9 96zM159.1 320C159.1 302.3 174.3 288 191.1 288C209.7 288 224 302.3 224 320C224 337.7 209.7 352 191.1 352C174.3 352 159.1 337.7 159.1 320z"
              />
              <path
                fill="#e6f4fe"
                d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM105.4 134.5C100.9 147 107.5 160.7 119.1 165.2C132.5 169.6 146.2 163.1 150.6 150.6L151.1 149.3C152.2 146.1 155.2 144 158.6 144H216.9C225.2 144 232 150.8 232 159.1C232 164.6 229.1 169.6 224.4 172.3L180.1 197.7C172.6 202 168 209.9 168 218.5V232C168 245.3 178.7 256 192 256C205.1 256 215.8 245.5 215.1 232.4L248.3 213.9C267.9 202.7 280 181.8 280 159.1C280 124.3 251.7 96 216.9 96H158.6C134.9 96 113.8 110.9 105.8 133.3L105.4 134.5zM192 288C174.3 288 160 302.3 160 320C160 337.7 174.3 352 192 352C209.7 352 224 337.7 224 320C224 302.3 209.7 288 192 288z"
              />
            </svg>
          </div>
          <div class="py-10">
            <div class="text-center">
              <h1 class="mt-2 text-4xl font-extrabold text-gray-800 tracking-tight sm:text-3xl">
                <i18n-t scope="global" keypath="views.404.title" />
              </h1>
              <p class="mt-2 text-base text-gray-500">
                <i18n-t scope="global" keypath="views.404.description" />
              </p>
              <div class="mt-6">
                <router-link :to="{ name: 'company.dashboard' }" class="link">
                  <i18n-t scope="global" keypath="views.404.button" />
                </router-link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </slot>
  </div>
  <slot v-else v-bind="$attrs" />
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

import LogoIcon from '@/components/static/LogoIcon.vue';

type Obj = Record<string | number | symbol, any>;

export default defineComponent({
  name: 'LoadingWrapper',
  components: {
    LogoIcon
  },
  inheritAttrs: false,
  props: {
    showLoading: {
      type: Boolean,
      default: true
    },
    object: {
      type: Object as PropType<Obj | null>,
      required: false
    },
    forceFullHeight: {
      type: Boolean,
      default: true
    }
  }
});
</script>
