import breadcrumbs from '@/components/breadcrumbs/breadcrumbsGenerator';

import Intake from '@/models/Intake';
import { PropertySingle as Property, PropertyApiSingleData } from '@/models/Property';
import { RepairApiSingleData } from '@/models/RepairInternal';
import Tenant from '@/models/Tenant';

import i18n from '@/i18n';

import { RepairFlow } from './helpers/api/RepairFlowHelper';
import { ContractApiData } from './helpers/api/companyEnviorment/ContractHelper';
import { PurchaseCostApiData } from './helpers/api/companyEnviorment/PropertyCostHelper';
import { PurchaseApiData } from './helpers/api/companyEnviorment/PurchaseHelper';
import { SepaApiData } from './helpers/api/companyEnviorment/SepaHelper';
import Agreement from './models/Agreement';
import Company from './models/Company';
import Invoice from './models/Invoice';
import { Space } from './models/Space';

const { t } = i18n.global;

export const service = breadcrumbs.create();

// ---------------------------- Properties ---------------------------- //
{
  const propertyRouteName = 'property.index';
  service.for(propertyRouteName, (trail) => {
    trail.push(t('views.property.title'), { name: propertyRouteName });
  });

  // properties > create
  {
    const routeName = 'property.create';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.create.title'), { name: routeName });
    });
  }

  // properties > vacancy

  {
    const routeName = 'property.vacancy.index';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.vacancy.page'), { name: routeName });
    });
  }

  // properties > show
  {
    const propertyShowrouteName = 'property.show';
    service.for<[Property]>(propertyShowrouteName, (trail, property) => {
      trail.parent(propertyRouteName);
      trail.push(property.full_address_city, {
        name: propertyShowrouteName,
        params: { id: property.id }
      });
    });

    // properties > show > Finances
    {
      const routeName = 'property.show.agreements.index';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent(propertyShowrouteName, property);
        trail.push(t('views.property.show.agreements.title'), {
          name: routeName,
          params: { id: property.id }
        });
      });
    }

    // properties > show > Spaces
    {
      const routeName = 'property.show.spaces.index';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent(propertyShowrouteName, property);
        trail.push(t('views.property.show.spaces.title'), {
          name: routeName,
          params: { id: property.id }
        });
      });
    }

    // properties > show > Spaces > show
    {
      const routeName = 'property.show.spaces.show';
      service.for<[Property, Space | undefined]>(routeName, (trail, property, space) => {
        trail.parent('property.show.spaces.index', property);
        trail.push(space?.name ?? '', {
          name: routeName,
          params: { id: property.id, spaceId: space?.id ?? 'NULL' }
        });
      });
    }

    // properties > show > Files
    {
      const routeName = 'property.show.files.index';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent(propertyShowrouteName, property);
        trail.push(t('views.property.show.files.title'), {
          name: routeName,
          params: { id: property.id }
        });
      });
    }

    // properties > show > maintenance
    {
      const routeName = 'property.show.maintenance.index';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent(propertyShowrouteName, property);
        trail.push(t('views.property.show.maintenance.title'), {
          name: routeName,
          params: { id: property.id }
        });
      });
    }

    // properties > show > installation
    {
      const routeName = 'property.show.installation.index';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent(propertyShowrouteName, property);
        trail.push(t('views.property.show.installation.title'), {
          name: routeName,
          params: { id: property.id }
        });
      });

      // properties > show > installation > show
      {
        const routeName = 'property.show.installation.show';
        service.for<[Property]>(routeName, (trail, property) => {
          trail.parent('property.show.installation.index', property);
          // trail.push(installation.id.toString(), {
          //   name: routeName,
          //   params: { id: property.id, installationId: installation.id }
          // });
        });
      }
    }

    // properties > show > Log
    {
      const routeName = 'property.show.log.index';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent(propertyShowrouteName, property);
        trail.push(t('views.property.show.log.title'), {
          name: routeName,
          params: { id: property.id }
        });
      });
    }

    // properties > show > settings
    {
      const routeName = 'property.show.settings.index';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent(propertyShowrouteName, property);
        trail.push(t('views.property.show.settings.title'), {
          name: routeName,
          params: { id: property.id }
        });
      });
    }
  }

  // properties > residential
  {
    const routeName = 'property.index.residential';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.residential.title'), { name: routeName });
    });
  }

  // properties > commercial
  {
    const routeName = 'property.index.commercial';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.commercial.title'), { name: routeName });
    });
  }

  // properties > misc
  {
    const routeName = 'property.index.misc';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.index.misc'), { name: routeName });
    });
  }

  // properties > archived
  {
    const routeName = 'property.index.archived';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.index.archived'), { name: routeName });
    });
  }

  // properties > reports
  {
    const routeName = 'property.reports';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.reports.title'), { name: routeName });
    });
  }

  // properties > gauges
  {
    const routeName = 'property.gauges';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.gauges.title'), { name: routeName });
    });
  }

  // properties > inspections
  {
    const routeName = 'property.inspection.index';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.inspections.title'), { name: routeName });
    });
  }

  // properties > map
  {
    const routeName = 'property.map';
    service.for(routeName, (trail) => {
      trail.parent(propertyRouteName);
      trail.push(t('views.property.map.title'), { name: routeName });
    });
  }
}

// ---------------------------- Tenants ---------------------------- //
{
  const tenantsRouteName = 'a.tenant.index';
  service.for(tenantsRouteName, (trail) => {
    trail.push(t('views.tenant.title'), { name: tenantsRouteName });
  });

  // tenants > persons
  {
    const routeName = 'a.tenant.index.persons';
    service.for(routeName, (trail) => {
      trail.parent(tenantsRouteName);
      trail.push(t('views.tenant.index.persons'), { name: routeName });
    });
  }

  // tenants > companies
  {
    const routeName = 'a.tenant.index.companies';
    service.for(routeName, (trail) => {
      trail.parent(tenantsRouteName);
      trail.push(t('views.tenant.index.companies'), { name: routeName });
    });
  }

  // tenants > create
  {
    const routeName = 'a.tenant.create';
    service.for(routeName, (trail) => {
      trail.parent(tenantsRouteName);
      trail.push(t('views.tenant.create.breadcrumb'), { name: routeName });
    });
  }

  // tenants > create
  {
    const routeName = 'a.tenant.create.manually';
    service.for(routeName, (trail) => {
      trail.parent('a.tenant.create');
      trail.push(t('views.tenant.create.manually.title'), { name: routeName });
    });
  }

  // tenant > show
  {
    const tenantShowrouteName = 'a.tenant.show';
    service.for<[Tenant]>(tenantShowrouteName, (trail, tenant) => {
      trail.parent(tenantsRouteName);
      trail.push(`${tenant.first_name} ${tenant.last_name}`, {
        name: tenantShowrouteName,
        params: { id: tenant.id }
      });
    });

    // tenant > show > agreements
    {
      const routeName = 'a.tenant.show.agreement.index';
      service.for<[Tenant]>(routeName, (trail, tenant) => {
        trail.parent(tenantShowrouteName, tenant);
        trail.push(t('views.tenant.show.agreements.title'), {
          name: routeName,
          params: { id: tenant.id }
        });
      });
    }

    // tenant > show > invoices
    {
      const routeName = 'a.tenant.show.invoice.index';
      service.for<[Tenant]>(routeName, (trail, tenant) => {
        trail.parent(tenantShowrouteName, tenant);
        trail.push(t('views.tenant.show.invoices.title'), {
          name: routeName,
          params: { id: tenant.id }
        });
      });
    }

    // tenant > show > files
    {
      const routeName = 'a.tenant.show.files.index';
      service.for<[Tenant]>(routeName, (trail, tenant) => {
        trail.parent(tenantShowrouteName, tenant);
        trail.push(t('views.tenant.show.files.title'), {
          name: routeName,
          params: { id: tenant.id }
        });
      });
    }

    // tenant > show > notes
    {
      const routeName = 'a.tenant.show.notes.index';
      service.for<[Tenant]>(routeName, (trail, tenant) => {
        trail.parent(tenantShowrouteName, tenant);
        trail.push(t('views.tenant.show.notes.title'), {
          name: routeName,
          params: { id: tenant.id }
        });
      });
    }

    // tenant > show > account
    {
      const routeName = 'a.tenant.show.account.index';
      service.for<[Tenant]>(routeName, (trail, tenant) => {
        trail.parent(tenantShowrouteName, tenant);
        trail.push(t('views.tenant.show.account.title'), {
          name: routeName,
          params: { id: tenant.id }
        });
      });
    }

    // tenant > show > contacts
    {
      const routeName = 'a.tenant.show.contact.index';
      service.for<[Tenant]>(routeName, (trail, tenant) => {
        trail.parent(tenantShowrouteName, tenant);
        trail.push(t('views.tenant.show.contacts.title'), {
          name: routeName,
          params: { id: tenant.id }
        });
      });
    }

    // tenant > show > balance
    {
      const routeName = 'a.tenant.show.balance.index';
      service.for<[Tenant]>(routeName, (trail, tenant) => {
        trail.parent(tenantShowrouteName, tenant);
        trail.push(t('views.tenant.show.balance.title'), {
          name: routeName,
          params: { id: tenant.id }
        });
      });
    }

    {
      const routeName = 'a.tenant.agreement.index';
      service.for(routeName, (trail) => {
        trail.parent(tenantsRouteName);
        trail.push(t('views.tenant.agreement.index.title'), {
          name: routeName
        });
      });
    }

    {
      const routeName = 'a.tenant.indexation.index';
      service.for(routeName, (trail) => {
        trail.parent(tenantsRouteName);
        trail.push(t('views.tenant.indexation.index.title'), {
          name: routeName
        });
      });
    }

    {
      const routeName = 'a.contracts';
      service.for<[ContractApiData]>(routeName, (trail, contract) => {
        trail.parent(tenantsRouteName);
        trail.push(t('components.contractShow.title'), {
          name: routeName,
          params: { id: contract.id }
        });
      });
    }

    {
      const routeName = 'a.tenant.intake.index';
      service.for(routeName, (trail) => {
        trail.parent(tenantsRouteName);
        trail.push(t('views.tenant.intake.index.title'), { name: routeName });
      });
    }

    {
      const routeName = 'a.tenant.intake.show';
      service.for<[Intake]>(routeName, (trail, intake) => {
        trail.parent('a.tenant.intake.index');
        trail.push(intake.email, {
          name: routeName,
          params: { id: intake.id }
        });
      });
    }
  }
}

// ---------------------------- Finance ---------------------------- //
{
  const financesRouteName = 'finances.overview';
  service.for(financesRouteName, (trail) => {
    trail.push(t('views.finances.title'), {
      name: financesRouteName
    });
  });

  // finances > rent
  {
    const routeName = 'finances.rent.index';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.rent.title'), {
        name: routeName
      });
    });
  }

  // finances > rent > monthly
  {
    const routeName = 'finances.rent.monthly';
    service.for(routeName, (trail, d: { month: number; year: number }) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.rent.title'), {
        name: routeName,
        params: { month: d.month, year: d.year }
      });
    });
  }

  // finances > invoices
  {
    const routeName = 'finances.invoices';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.invoices.title'), { name: routeName });
    });
  }

  // finances > invoices > show
  {
    const routeName = 'finances.invoice.show';
    service.for<[Invoice]>(routeName, (trail, invoice) => {
      trail.parent('finances.invoices');
      trail.push(invoice.number, {
        name: routeName,
        params: { uuid: invoice.uuid }
      });
    });
  }

  // finances > payouts
  {
    const routeName = 'finances.payouts';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.payouts.title'), { name: routeName });
    });
  }

  // finances > reports
  {
    const routeName = 'finances.reports';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.reports.title'), { name: routeName });
    });
  }

  // finances > transactions
  {
    const routeName = 'finances.transaction.index';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('bizcuit.page.link'), { name: routeName });
    });
  }

  // finances > purchase
  {
    const routeName = 'finances.purchase.index';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.purchase.index'), { name: routeName });
    });
  }

  // finances > purchase > show
  {
    const routeName = 'finances.purchase.show';
    service.for<[PurchaseApiData]>(routeName, (trail, purchase) => {
      trail.parent('finances.purchase.index');
      trail.push(purchase.invoice_reference, {
        name: routeName,
        params: { id: purchase.id }
      });
    });
  }

  // finances > purchase
  {
    const routeName = 'finances.contact.index';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.contacts.title'), { name: routeName });
    });
  }

  {
    const routeName = 'finances.contact.show';
    service.for<[ContractApiData]>(routeName, (trail, contact) => {
      trail.parent('finances.contact.index');
      trail.push(contact.name, {
        name: routeName,
        params: { id: contact.id }
      });
    });
  }

  // finances > sepa
  {
    const routeName = 'finances.sepa';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.sepa.title'), {
        name: routeName
      });
    });
  }

  // finances > sepa > show
  {
    const routeName = 'finances.sepa.show';
    service.for<[SepaApiData]>(routeName, (trail, sepa) => {
      trail.parent('finances.sepa');
      trail.push(sepa.period.toString(), {
        name: routeName,
        params: { id: sepa.id }
      });
    });
  }

  // finances > cost type
  {
    const routeName = 'finances.costType.index';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.costType.title'), {
        name: routeName
      });
    });
  }

  // finances > cost overview
  {
    const routeName = 'finances.property.costs.index';
    service.for(routeName, (trail) => {
      trail.parent(financesRouteName);
      trail.push(t('views.finances.costs.title'), {
        name: routeName
      });
    });
  }

  // finances > cost overview > show
  {
    const routeName = 'finances.property.costs.show';
    service.for<[PurchaseCostApiData]>(routeName, (trail, cost) => {
      trail.parent('finances.property.costs.index');
      trail.push(cost.full_address.toString(), {
        name: routeName,
        params: { id: cost.id }
      });
    });
  }
}

// ---------------------------- Maintenance ---------------------------- //
{
  const maintenanceRouteName = 'maintenance.assignment.index';
  service.for(maintenanceRouteName, (trail) => {
    trail.push(t('views.maintenance.assignment.title'), {
      name: maintenanceRouteName
    });
  });

  {
    const routeName = 'maintenance.assignment.closed';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.assignment.overview.closed'), {
        name: routeName
      });
    });
  }

  // maintenance > assignment > show
  {
    const routeName = 'maintenance.assignment.show';
    service.for<[RepairApiSingleData]>(routeName, (trail, repair) => {
      trail.parent(maintenanceRouteName);
      trail.push(repair.ticket, {
        name: routeName,
        params: { id: repair.id }
      });
    });
  }

  // reports
  {
    const routeName = 'maintenance.report.index';
    service.for(routeName, (trail) => {
      trail.push(t('views.maintenance.reports.title'), {
        name: routeName
      });
    });
  }

  {
    const routeName = 'maintenance.report.show';
    service.for<[RepairApiSingleData]>(routeName, (trail, repair) => {
      trail.parent('maintenance.report.index');
      trail.push(repair.ticket, {
        name: routeName,
        params: { id: repair.id }
      });
    });
  }

  // maintenance > calendar
  {
    const routeName = 'maintenance.calendar';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.calendar.title'), { name: routeName });
    });
  }

  // maintenance > services
  {
    const routeName = 'maintenance.services';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.services.title'), { name: routeName });
    });
  }

  {
    const routeName = 'maintenance.services.show';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.services.show.title'), {
        name: routeName
      });
    });
  }

  // maintenance > team
  {
    const routeName = 'maintenance.team';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.team.title'), { name: routeName });
    });
  }
}

// ---------------------------- Communication ---------------------------- //
{
  const communicationRouteName = 'communication.messages.index';
  service.for(communicationRouteName, (trail) => {
    trail.push(t('views.communication.messages.title'), {
      name: communicationRouteName
    });
  });

  {
    const routeName = 'communication.messages.unread';
    service.for(routeName, (trail) => {
      trail.parent(communicationRouteName);
      trail.push(t('views.communication.messages.unread'), {
        name: routeName
      });
    });
  }
  {
    const routeName = 'communication.messages.archive';
    service.for(routeName, (trail) => {
      trail.parent(communicationRouteName);
      trail.push(t('views.communication.messages.archive'), {
        name: routeName
      });
    });
  }

  // communication > announcements > index
  const announcementrouteName = 'communication.announcements.index';
  {
    service.for(announcementrouteName, (trail) => {
      trail.push(t('views.communication.announcements.title'), {
        name: announcementrouteName
      });
    });
  }

  // communication > announcements > drafts
  {
    const routeName = 'communication.announcements.drafts';
    service.for(routeName, (trail) => {
      trail.parent(announcementrouteName);
      trail.push(t('views.communication.announcements.drafts'), {
        name: routeName
      });
    });
  }

  // communication > automations
  // {
  //   const routeName = "communication.automations.index";
  //   service.for(routeName, trail => {
  //     trail.push(t("views.communication.automations.title"), {
  //       name: routeName
  //     });
  //   });
  // }
}

// ---------------------------- Settings ---------------------------- //
{
  const settingsRouteName = 'settings';
  service.for(settingsRouteName, (trail) => {
    trail.push(t('views.settings.title'), { name: settingsRouteName });
  });

  // settings > users
  {
    const routeName = 'settings.users';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.users.title'), { name: routeName });
    });
  }

  // settings > agreements
  {
    const routeName = 'settings.agreements';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.agreements.contracts.title'), {
        name: routeName
      });
    });
  }

  // settings > checkups
  {
    const routeName = 'settings.checkups';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.checkups.title'), { name: routeName });
    });
  }

  // settings > repairflows
  {
    const routeName = 'settings.repairflows';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.repairflows.title'), { name: routeName });
    });
  }
  {
    const routeName = 'settings.repairflows.show';
    service.for<[RepairFlow]>(routeName, (trail, flow) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.repairflows.title'), {
        name: 'settings.repairflows'
      });
      trail.push(flow.name, { name: routeName, params: { id: flow.id } });
    });
  }

  // settings > installations
  {
    const routeName = 'settings.installations';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.installations.title'), {
        name: routeName
      });
    });
  }

  // settings > branding
  {
    const routeName = 'settings.branding';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.branding.title'), { name: routeName });
    });
  }

  // settings > finances
  {
    const routeName = 'settings.finances';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.finances.title'), { name: routeName });
    });
  }

  // settings > maintenance
  {
    const routeName = 'settings.maintenance';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.maintenance.title'), { name: routeName });
    });
  }

  // settings > subscriptions
  {
    const routeName = 'settings.subscriptions';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.subscriptions.title'), { name: routeName });
    });
  }

  // settings > subscriptions
  {
    const routeName = 'settings.integrations';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.integrations.title'), { name: routeName });
    });
  }

  {
    const routeName = 'settings.integrations.exact';
    service.for(routeName, (trail) => {
      trail.parent('settings.integrations');
      trail.push(t('views.settings.integrations.exact.title'), {
        name: routeName
      });
    });
  }
  {
    const routeName = 'settings.integrations.exact.rentables';
    service.for(routeName, (trail) => {
      trail.parent('settings.integrations.exact');
      trail.push(t('views.settings.integrations.exact.rentables.title'), {
        name: routeName
      });
    });
  }
  {
    const routeName = 'settings.integrations.exact.vat';
    service.for(routeName, (trail) => {
      trail.parent('settings.integrations.exact');
      trail.push(t('views.settings.integrations.exact.vat.title'), {
        name: routeName
      });
    });
  }
  {
    const routeName = 'settings.integrations.exact.tenants';
    service.for(routeName, (trail) => {
      trail.parent('settings.integrations.exact');
      trail.push(t('views.settings.integrations.exact.tenants.title'), {
        name: routeName
      });
    });
  }

  // settings > intergrations > bizcuit
  {
    const routeName = 'settings.integrations.bizcuit';
    service.for(routeName, (trail) => {
      trail.parent('settings.integrations');
      trail.push('Bizcuit', { name: routeName });
    });
  }

  // settings > intergrations > huurprijscheck
  {
    const routeName = 'settings.integrations.huurprijscheck';
    service.for(routeName, (trail) => {
      trail.parent('settings.integrations');
      trail.push('Huurprijscheck', { name: routeName });
    });
  }

  // settings > bookkeeping
  {
    const routeName = 'settings.bookkeeping';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.bookkeeping.title'), { name: routeName });
    });
  }

  // settings > legal
  {
    const routeName = 'settings.legal';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.legal.title'), { name: routeName });
    });
  }

  // settings > removeData
  {
    const routeName = 'settings.removeData';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.removeData.title'), { name: routeName });
    });
  }
}

const settingsRouteName = 'management.settings';
service.for(settingsRouteName, (trail) => {
  trail.push(t('views.settings.title'), { name: settingsRouteName });
});

// settings > finances
{
  const routeName = 'management.settings.finances';
  service.for(routeName, (trail) => {
    trail.parent(settingsRouteName);
    trail.push(t('views.settings.finances.title'), { name: routeName });
  });
}

// settings > installations
{
  const routeName = 'management.settings.installations';
  service.for(routeName, (trail) => {
    trail.parent(settingsRouteName);
    trail.push(t('views.settings.installations.title'), { name: routeName });
  });
}

// settings > subscriptions
{
  const routeName = 'management.settings.subscriptions';
  service.for(routeName, (trail) => {
    trail.parent(settingsRouteName);
    trail.push(t('views.settings.subscriptions.title'), { name: routeName });
  });
}

// settings > branding
{
  const routeName = 'management.settings.branding';
  service.for(routeName, (trail) => {
    trail.parent(settingsRouteName);
    trail.push(t('views.settings.branding.title'), { name: routeName });
  });
}

// ---------------------------- Account ---------------------------- //
{
  const accountRouteName = 'account';
  service.for(accountRouteName, (trail) => {
    trail.push(t('views.account.title'), { name: accountRouteName });
  });
}

// ---------------------------- Notifications ---------------------------- //
{
  const notificationsRouteName = 'company.notifications';
  service.for(notificationsRouteName, (trail) => {
    trail.push(t('views.notifications.title'), {
      name: notificationsRouteName
    });
  });
}

// ---------------------------- Tenant Routes ---------------------------- //

{
  const invoiceRouteName = 'tenant.invoice.index';
  service.for(invoiceRouteName, (trail) => {
    trail.push(t('viewsTenant.invoice.title'), {
      name: invoiceRouteName
    });
  });

  {
    const routeName = 'tenant.invoice.show';
    service.for<[Invoice]>(routeName, (trail, invoice) => {
      trail.parent(invoiceRouteName);
      trail.push(t('viewsTenant.invoice.title'), {
        name: routeName,
        params: { invoiceUuid: invoice.uuid }
      });
    });
  }
}

{
  const agreementRouteName = 'tenant.agreement.index';
  service.for(agreementRouteName, (trail) => {
    trail.push(t('viewsTenant.agreement.title'), {
      name: agreementRouteName
    });
  });

  {
    const routeName = 'tenant.agreement.show';
    service.for<[Agreement]>(routeName, (trail, agreement) => {
      trail.parent(agreementRouteName);
      trail.push(agreement?.property?.full_address ?? '', {
        name: routeName,
        params: { agreementId: agreement.id }
      });
    });
  }
}

{
  const routeName = 'tenant.message.index';
  service.for(routeName, (trail) => {
    trail.push(t('viewsTenant.message.title'), {
      name: routeName
    });
  });
}

{
  const routeName = 'tenant.announcement.index';
  service.for(routeName, (trail) => {
    trail.push(t('viewsTenant.announcement.title'), {
      name: routeName
    });
  });
}

{
  const routeName = 'tenant.announcement.show';
  service.for(routeName, (trail) => {
    trail.push(t('viewsTenant.announcement.title'), {
      name: 'tenant.announcement.index'
    });
  });
}

// ---------------------------- Service Routes ---------------------------- //

{
  const routeName = 'service.account';
  service.for(routeName, (trail) => {
    trail.push(t('views.account.title'), { name: routeName });
  });
}

{
  const routeName = 'service.assignment.index';
  service.for(routeName, (trail) => {
    trail.push(t('viewsService.assignment.title'), { name: routeName });
  });
}

{
  const routeName = 'service.assignment.service';
  service.for(routeName, (trail) => {
    trail.push(t('views.maintenance.services.title'), { name: routeName });
  });
}

{
  const routeName = 'service.assignment.service.show';
  service.for(routeName, (trail) => {
    trail.push(t('views.maintenance.services.show.title'), { name: routeName });
  });
}

{
  const routeName = 'service.assignment.service.invites';
  service.for(routeName, (trail) => {
    trail.parent('service.assignment.service');
    trail.push(t('views.maintenance.services.index.inviteService'), {
      name: routeName
    });
  });
}

{
  const routeName = 'service.assignment.show';
  service.for<[RepairApiSingleData]>(routeName, (trail, repair) => {
    trail.parent('service.assignment.index');

    trail.push(repair.ticket, {
      name: routeName,
      params: { id: repair.id }
    });
  });
}

{
  const routeName = 'service.calendar';
  service.for(routeName, (trail) => {
    trail.push(t('menu.maintenance.children.calendar'), { name: routeName });
  });
}

{
  const routeName = 'service.location.index';
  service.for(routeName, (trail) => {
    trail.push(t('viewsService.location.title'), { name: routeName });
  });
}

{
  const routeName = 'service.location.show';
  service.for<[PropertyApiSingleData]>(routeName, (trail, property) => {
    trail.parent('service.location.index');

    trail.push(property.full_address, {
      name: routeName,
      params: { id: property.id }
    });
  });
}

{
  const routeName = 'service.team.index';
  service.for(routeName, (trail) => {
    trail.push(t('viewsService.team.title'), { name: routeName });
  });
}

{
  const routeName = 'service.client.index';
  service.for(routeName, (trail) => {
    trail.push(t('viewsService.client.title'), { name: routeName });
  });
}

{
  const routeName = 'service.client.company.show';
  service.for<[RepairApiSingleData]>(routeName, (trail, client) => {
    trail.parent('service.client.index');

    trail.push(client.name, {
      name: routeName,
      params: { id: client.id }
    });
  });
}

{
  const routeName = 'service.client.serviceClient.show';
  service.for<[RepairApiSingleData]>(routeName, (trail, client) => {
    trail.parent('service.client.index');

    trail.push(client.name, {
      name: routeName,
      params: { id: client.id }
    });
  });
}

{
  const routeName = 'service.map';
  service.for(routeName, (trail) => {
    trail.push(t('viewsService.map.title'), { name: routeName });
  });
}

{
  const routeName = 'service.settings';
  service.for(routeName, (trail) => {
    trail.push(t('viewsService.settings.title'), { name: routeName });
  });
}
{
  const routeName = 'service.settings.repairflows';
  service.for(routeName, (trail) => {
    trail.push(t('views.settings.repairflows.title'), { name: routeName });
  });
}
{
  const routeName = 'service.settings.repairflows.show';
  service.for<[RepairFlow]>(routeName, (trail, flow) => {
    trail.push(t('views.settings.repairflows.title'), {
      name: 'service.settings.repairflows'
    });
    trail.push(flow.name, { name: routeName, params: { id: flow.id } });
  });
}
{
  const routeName = 'service.settings.branding';
  service.for(routeName, (trail) => {
    trail.push(t('views.settings.branding.title'), { name: routeName });
  });
}

// ---------------------------- Management Routes ---------------------------- //
{
  const routeName = 'management.dashboard';
  service.for(routeName, (trail) => {
    // Empty on purpose; don't attach routes.
  });
}

{
  const routeName = 'management.company.index';
  service.for(routeName, (trail) => {
    trail.push(t('viewsManagement.company.index.title'), { name: routeName });
  });
}

// reports
{
  const routeName = 'management.repair.index';
  service.for(routeName, (trail) => {
    trail.push(t('views.maintenance.reports.title'), {
      name: routeName
    });
  });
}
{
  const routeName = 'management.repair.closed';
  service.for(routeName, (trail) => {
    trail.parent('management.repair.index');
    trail.push(t('views.maintenance.assignment.overview.closed'), {
      name: routeName
    });
  });
}
{
  const routeName = 'management.repair.show';
  service.for<[RepairApiSingleData]>(routeName, (trail, repair) => {
    trail.parent('management.repair.index');
    trail.push(repair.ticket, {
      name: routeName,
      params: { id: repair.id }
    });
  });
}

// management > calendar
{
  const routeName = 'management.repair.calendar';
  service.for(routeName, (trail) => {
    trail.push(t('views.maintenance.calendar.title'), { name: routeName });
  });
}

{
  const routeName = 'management.property.index';
  service.for(routeName, (trail) => {
    trail.push(t('views.property.title'), { name: routeName });
  });
}

{
  const managementFinancesRouteName = 'management.finances.invoice.index';
  service.for(managementFinancesRouteName, (trail) => {
    trail.push(t('viewsManagement.finances.title'), {
      name: managementFinancesRouteName
    });
  });

  // finances > billing
  {
    const routeName = 'management.finances.billing.index';
    service.for(routeName, (trail) => {
      trail.parent(managementFinancesRouteName);
      trail.push(t('viewsManagement.finances.billing.title'), {
        name: routeName
      });
    });
  }

  // finances > billing > show
  {
    const routeName = 'management.finances.billing.show';
    service.for<[Company]>(routeName, (trail, company) => {
      trail.parent('management.finances.billing.index');
      trail.push(company.name, { name: routeName });
    });
  }

  // finances > payouts
  {
    const routeName = 'management.finances.payouts.index';
    service.for(routeName, (trail) => {
      trail.parent(managementFinancesRouteName);
      trail.push(t('menu.finances.children.payouts'), {
        name: routeName
      });
    });
  }
}

//////////////////////////////////////////////////////////////////////////////////////////////
//  maintenance_employee

/////////////////////////////////////
// MAINTENANCE PROPERTY
{
  const propertyIndex = 'am.property.index';
  service.for(propertyIndex, (trail) => {
    trail.push(t('views.property.title'), { name: propertyIndex });
  });

  {
    const routeName = 'am.property.index.residential';
    service.for(routeName, (trail) => {
      trail.parent(propertyIndex);
      trail.push(t('views.property.residential.title'), { name: routeName });
    });
  }

  // properties > commercial
  {
    const routeName = 'am.property.index.commercial';
    service.for(routeName, (trail) => {
      trail.parent(propertyIndex);
      trail.push(t('views.property.commercial.title'), { name: routeName });
    });
  }

  // properties > misc
  {
    const routeName = 'am.property.index.misc';
    service.for(routeName, (trail) => {
      trail.parent(propertyIndex);
      trail.push(t('views.property.index.misc'), { name: routeName });
    });
  }

  // properties > inspections
  {
    const routeName = 'am.property.inspection.index';
    service.for(routeName, (trail) => {
      trail.parent(propertyIndex);
      trail.push(t('views.property.inspections.title'), { name: routeName });
    });
  }

  // properties > map
  {
    const routeName = 'am.property.map';
    service.for(routeName, (trail) => {
      trail.parent(propertyIndex);
      trail.push(t('views.property.map.title'), { name: routeName });
    });
  }

  const propertyShow = 'am.property.show';
  service.for<[Property]>(propertyShow, (trail, property) => {
    trail.parent(propertyIndex);
    trail.push(property.full_address_city, {
      name: propertyShow,
      params: { id: property.id }
    });
  });

  // properties > show > Spaces
  {
    const routeName = 'am.property.show.spaces.index';
    service.for<[Property]>(routeName, (trail, property) => {
      trail.parent(propertyShow, property);
      trail.push(t('views.property.show.spaces.title'), {
        name: routeName,
        params: { id: property.id }
      });
    });
  }

  // properties > show > Spaces > show
  {
    const routeName = 'am.property.show.spaces.show';
    service.for<[Property, Space | undefined]>(routeName, (trail, property, space) => {
      trail.parent(routeName, property);
      trail.push(space?.name ?? '', {
        name: routeName,
        params: { id: property.id, spaceId: space?.id ?? 'NULL' }
      });
    });
  }

  // properties > show > maintenance
  {
    const routeName = 'am.property.show.maintenance.index';
    service.for<[Property]>(routeName, (trail, property) => {
      trail.parent(propertyShow, property);
      trail.push(t('views.property.show.maintenance.title'), {
        name: routeName,
        params: { id: property.id }
      });
    });
  }

  // properties > show > installation
  {
    const routeName = 'am.property.show.installation.index';
    service.for<[Property]>(routeName, (trail, property) => {
      trail.parent(propertyShow, property);
      trail.push(t('views.property.show.installation.title'), {
        name: routeName,
        params: { id: property.id }
      });
    });

    // properties > show > installation > show
    {
      const routeName = 'am.property.show.installation.show';
      service.for<[Property]>(routeName, (trail, property) => {
        trail.parent('property.show.installation.index', property);
      });
    }
  }
}

/////////////////////////////////////////////
// MAINTENANCE MAINTENANCE

{
  const maintenanceRouteName = 'am.maintenance.assignment.index';
  service.for(maintenanceRouteName, (trail) => {
    trail.push(t('views.maintenance.assignment.title'), {
      name: maintenanceRouteName
    });
  });

  {
    const routeName = 'am.maintenance.assignment.closed';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.assignment.overview.closed'), {
        name: routeName
      });
    });
  }

  // maintenance > assignment > show
  {
    const routeName = 'am.maintenance.assignment.show';
    service.for<[RepairApiSingleData]>(routeName, (trail, repair) => {
      trail.parent(maintenanceRouteName);
      trail.push(repair.ticket, {
        name: routeName,
        params: { id: repair.id }
      });
    });
  }

  // reports
  {
    const routeName = 'am.maintenance.report.index';
    service.for(routeName, (trail) => {
      trail.push(t('views.maintenance.reports.title'), {
        name: routeName
      });
    });
  }

  {
    const routeName = 'am.maintenance.report.show';
    service.for<[RepairApiSingleData]>(routeName, (trail, repair) => {
      trail.parent('am.maintenance.report.index');
      trail.push(repair.ticket, {
        name: routeName,
        params: { id: repair.id }
      });
    });
  }

  // maintenance > calendar
  {
    const routeName = 'am.maintenance.calendar';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.calendar.title'), { name: routeName });
    });
  }

  // maintenance > services
  {
    const routeName = 'am.maintenance.services';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.services.title'), { name: routeName });
    });
  }

  {
    const routeName = 'am.maintenance.services.show';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.services.show.title'), {
        name: routeName
      });
    });
  }

  // maintenance > team
  {
    const routeName = 'am.maintenance.team';
    service.for(routeName, (trail) => {
      trail.parent(maintenanceRouteName);
      trail.push(t('views.maintenance.team.title'), { name: routeName });
    });
  }
}

/////////////////////////////////////////////////////////////
// MAINTENANCE COMUNICATION
{
  const communicationRouteName = 'am.communication.messages.index';
  service.for(communicationRouteName, (trail) => {
    trail.push(t('views.communication.messages.title'), {
      name: communicationRouteName
    });
  });

  {
    const routeName = 'am.communication.messages.unread';
    service.for(routeName, (trail) => {
      trail.parent(communicationRouteName);
      trail.push(t('views.communication.messages.unread'), {
        name: routeName
      });
    });
  }
  {
    const routeName = 'am.communication.messages.archive';
    service.for(routeName, (trail) => {
      trail.parent(communicationRouteName);
      trail.push(t('views.communication.messages.archive'), {
        name: routeName
      });
    });
  }

  // communication > announcements > index
  const announcementrouteName = 'am.communication.announcements.index';
  {
    service.for(announcementrouteName, (trail) => {
      trail.push(t('views.communication.announcements.title'), {
        name: announcementrouteName
      });
    });
  }

  // communication > announcements > drafts
  {
    const routeName = 'am.communication.announcements.drafts';
    service.for(routeName, (trail) => {
      trail.parent(announcementrouteName);
      trail.push(t('views.communication.announcements.drafts'), {
        name: routeName
      });
    });
  }
}

//////////////////////////////////////////////////
// MAINTENANCE SETTINGS
{
  {
    const routeName = 'am.settings.users';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.users.title'), { name: routeName });
    });
  }

  // settings > repairflows
  const repairflowsIndex = 'am.settings.repairflows';
  {
    service.for(repairflowsIndex, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.repairflows.title'), { name: repairflowsIndex });
    });
  }
  {
    const routeName = 'am.settings.repairflows.show';
    service.for<[RepairFlow]>(routeName, (trail, flow) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.repairflows.title'), { name: repairflowsIndex });
      trail.push(flow.name, { name: routeName, params: { id: flow.id } });
    });
  }
  {
    const routeName = 'am.settings.checkups';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.checkups.title'), { name: routeName });
    });
  }

  {
    const routeName = 'am.settings.installations';
    service.for(routeName, (trail) => {
      trail.parent(settingsRouteName);
      trail.push(t('views.settings.installations.title'), {
        name: routeName
      });
    });
  }
}

export default service;
