import { ActionContext } from 'vuex';

import { Notification, NotificationType } from '@/components/notifications/types';

import { storeLogger } from '../Logger';
import { State as RootState } from '../state';

// ---------------- STATE ---------------- //
export interface NotificationState {
  notifications: Array<Notification>;
  nextId: number;
}
const state: NotificationState = {
  notifications: [],
  nextId: 1
};

// ---------------- MUTATIONS ---------------- //
const mutations = {
  setNotification(state: NotificationState, notification: { type: NotificationType; text: string; title?: string }) {
    storeLogger('setNotification', state, notification);
    state.notifications.push({
      id: state.nextId++,
      dismissed: false,
      ...notification
    });
  },
  dismissNotification(state: NotificationState, id: number) {
    storeLogger('dismissNotification', state, id);
    const notification = state.notifications.find((m) => m.id == id);

    if (notification) {
      notification.dismissed = true;
    }
  },
  destroyNotification(state: NotificationState, id: number) {
    storeLogger('destroyNotification', state, id);
    state.notifications = state.notifications.filter((m) => m.id !== id);
  }
};

// ---------------- ACTIONS ---------------- //
type NotificationContext = ActionContext<NotificationState, RootState>;
const actions = {
  setError({ commit }: NotificationContext, payload: { text: string; title?: string } | string) {
    if (typeof payload === 'string') {
      payload = { text: payload };
    }
    commit('setNotification', {
      type: NotificationType.ERROR,
      ...payload
    });
  },
  setWarning({ commit }: NotificationContext, payload: { text: string; title?: string } | string) {
    if (typeof payload === 'string') {
      payload = { text: payload };
    }
    commit('setNotification', {
      type: NotificationType.WARN,
      ...payload
    });
  },
  setSuccess({ commit }: NotificationContext, payload: { text: string; title?: string } | string) {
    if (typeof payload === 'string') {
      payload = { text: payload };
    }
    commit('setNotification', {
      type: NotificationType.SUCC,
      ...payload
    });
  },
  setInfo({ commit }: NotificationContext, payload: { text: string; title?: string } | string) {
    if (typeof payload === 'string') {
      payload = { text: payload };
    }
    commit('setNotification', {
      type: NotificationType.INFO,
      ...payload
    });
  }
};

// ---------------- GETTERS ---------------- //
const getters = {
  getNotifications: (state: NotificationState) => {
    return state.notifications.filter(({ dismissed }) => !dismissed);
  }
};

export default {
  // eslint-disable @typescript-eslint/prefer-as-const
  namespaced: true as const,
  state,
  mutations,
  actions,
  getters
};
