<template>
  <slot />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageMetaData',
  props: {
    title: {
      type: String,
      default: 'Vastgoed App'
    }
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      }
    }
  }
});
</script>
