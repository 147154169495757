interface TableState {
  filters: string[];
  search: string;
  page: number;
}

const state: TableState = {
  filters: [],
  search: '',
  page: 1
};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  increment: (state: TableState) => state.page++,
  decrement: (state: TableState) => state.page--
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
