import { companyBaseUrl, get } from '..';
import { ExactInternal } from './ExactLinkTypes';

export enum LogisticalItemType {
  agreement = 'agreement'
}

export type LogisticalItem = {
  id: number;
  name: string;
  amount: number;
  description: string | null;
  type: LogisticalItemType;
  exacts?: ExactInternal[];
  logistical_type?: LogisticalType;
  discount_ends_at: Date | null;
  discount_percentage: number | null;
};

export enum LogisticalTypeId {
  rent = 1,
  service = 2,
  deposit = 3,
  other = 4
}

export type LogisticalType = {
  id: LogisticalTypeId;
  indexation: boolean;
  name: string;
  required: boolean;
  multiple: boolean;
  description: boolean;
  logistical_items?: LogisticalItem[];
};

export function getLogisticalTypes(type: LogisticalItemType = LogisticalItemType.agreement) {
  const url = companyBaseUrl(`/logistical/types?type=${type}&include=exacts`);

  return get<{ logistical_types: LogisticalType[] }>(url, 'logistical_types');
}

export function getAllLogisticalTypes() {
  const url = companyBaseUrl(`/logistical/types?include=exacts`);

  return get<{ logistical_types: LogisticalType[] }>(url, 'logistical_types');
}
