import { ActionContext } from 'vuex';

import { Links, Meta } from '@/models/Pagination';

import { baseUrl, patch } from '@/helpers/api';
import { byDateNewToOld } from '@/helpers/javascript/DateHelper';

import axios from '@/axios';

import { storeLogger } from '../Logger';
import { State as RootState } from '../state';

export type NotificationType =
  | 'App\\Notifications\\Intake\\IntakeReviewNotification'
  | 'App\\Notifications\\Repair\\RepairAssignedNotification'
  | 'App\\Notifications\\Repair\\RepairForwardNotification'
  | 'App\\Notifications\\Repair\\RepairStoreNotification'
  | 'App\\Notifications\\Transaction\\TransactionCompletedNotification'
  | 'App\\Notifications\\Invoice\\InvoiceNotification'
  | 'App\\Notifications\\Agreement\\AgreementAlmostExpiredAlertNotification'
  | 'App\\Notifications\\Indexation\\IndexationDeadlineNotification';

export type Notification = {
  id: string;
  message: string;
  data: Record<string, string | number>;
  read_at: string | null;
  type: NotificationType;
  created_at: string;
};

type NotificationMeta = Meta & { unread_notifications_count: number };

// ---------------- STATE ---------------- //
export interface NotificationMessageState {
  notifications: Map<string, Notification>;
  links?: Links;
  meta?: NotificationMeta;
}
const state: NotificationMessageState = {
  notifications: new Map(),
  links: undefined,
  meta: undefined
};

// ---------------- MUTATIONS ---------------- //
const mutations = {
  setNotification(state: NotificationMessageState, notification: Notification) {
    storeLogger('setNotification', state, notification);

    state.notifications.set(notification.id, notification);
  },
  setNotifications(
    state: NotificationMessageState,
    payload: {
      notifications: Notification[];
      links: Links;
      meta: NotificationMeta;
    }
  ): void {
    storeLogger('setNotifications', state, payload);

    payload.notifications.forEach((notification) => {
      state.notifications.set(notification.id, notification);
    });
    state.meta = payload.meta;
    state.links = payload.links;
  },
  decrementUnreadCount(state: NotificationMessageState) {
    if (state.meta?.unread_notifications_count) {
      state.meta.unread_notifications_count--;
    } else {
      console.warn('tried to decrement unread count while it is undefined or 0');
    }
  },
  markAllNotificationsAsRead(state: NotificationMessageState) {
    state.notifications.forEach((notification) => {
      notification.read_at = new Date().toISOString();
    });

    if (state.meta?.unread_notifications_count) {
      state.meta.unread_notifications_count = 0;
    }
  }
};

// ---------------- ACTIONS ---------------- //
type NotificationMessageContext = ActionContext<NotificationMessageState, RootState>;
const actions = {
  async getNotifications({ commit }: NotificationMessageContext) {
    const url = baseUrl('/notification');
    const response = await axios.get<{ notifications: Notification[] }>(url);

    commit('setNotifications', response.data);
  },
  async getMoreNotifications({ commit, state }: NotificationMessageContext) {
    if (state.links?.next === null) {
      console.warn('Tried calling "getMoreNotifications" on last page');
      return;
    }
    const url = state.links?.next ?? baseUrl('/notification');
    const response = await axios.get<{ notifications: Notification[] }>(url);

    commit('setNotifications', response.data);
  },
  async markNotificationAsRead({ commit }: NotificationMessageContext, notificationId: string) {
    if (!notificationId) {
      throw new Error('Forgot to pass notificationId to markNotificationAsRead function');
    }
    const url = baseUrl(`/notification/${notificationId}`);
    const notification = await patch<{ notification: Notification }>(url, 'notification', null);

    commit('setNotification', notification);
    commit('decrementUnreadCount');
  },
  async markAllNotificationsAsRead({ commit }: NotificationMessageContext) {
    const url = baseUrl(`/notification/`);
    await patch(url, '', null);

    commit('markAllNotificationsAsRead');
  }
};

// ---------------- GETTERS ---------------- //
const getters = {
  notifications: (state: NotificationMessageState) => {
    return Array.from(state.notifications.values()).sort(byDateNewToOld((notification) => new Date(notification.created_at)));
  },
  hasUnreadNotifications: (state: NotificationMessageState, getters: any) => {
    if (state.meta?.unread_notifications_count === undefined) {
      return false;
    }
    return state.meta.unread_notifications_count > 0;
  },
  getUnreadNotificationCount: (state: NotificationMessageState) => {
    return state.meta?.unread_notifications_count;
  },
  canLoadMore: (state: NotificationMessageState) => {
    return state.links?.next !== null;
  }
};

export default {
  // eslint-disable @typescript-eslint/prefer-as-const
  namespaced: true as const,
  state,
  mutations,
  actions,
  getters
};
