//

export function nodeEnvIsDevelopment(): boolean {
  return import.meta.env.DEV;
}

export function evaluateBooleanEnv(envVar: any): boolean {
  return envVar === 'true' || envVar === '1';
}

export function isEnvSet(envVar: any): boolean {
  return envVar !== undefined;
}
