import { ActiveFilters } from '@/components/table/components/filtering/types';
import { SortSettings } from '@/components/table/components/sorting/types';

import { Data, Item } from './GenericApiData';

export type Links = {
  first: string;
  last: string;
  next: string | null;
  prev: string | null;
};

export type Link = {
  active: boolean;
  label: string | number;
  url: string | null;
};

export type CustomMetaProperty = string[] | Record<string, string>;

export type OnlyMeta = {
  current_page: number;
  from: number | null;
  last_page: number;
  path: string;
  per_page: number;
  to: number | null;
  total: number;
  links: Link[];
};

export function metaHasCustomProperties(meta: Meta | OnlyMeta): meta is Meta {
  const onlyMetaPropertyCount = 8;
  return Object.keys(meta).length > onlyMetaPropertyCount;
}

export type Meta = OnlyMeta & Record<string, CustomMetaProperty>;

// -------------- API relevant types-------------- //

// converts a PaginatedAPIResponse to PaginatedData
// @param key: give the key on the object that should be converted to the 'data' value
export function convertToPaginatedData<I extends Item, T extends APIObject<I>>(paginatedAPIResponse: PaginatedAPIResponse<T>, key: keyof T): PaginatedData<I> {
  const { links, meta } = paginatedAPIResponse;
  const object = paginatedAPIResponse;
  const data = object[key];

  return { links, meta, data };
}

type Pagination = {
  links?: Links;
  meta?: Meta | OnlyMeta;
};

// Todo: add generic type to paginated data
export type PaginatedData<T = Item> = Data<T> & Pagination;

export type APIObject<T extends Item = Item> = {
  [x: string]: T[];
};

export type PaginatedAPIResponse<T extends APIObject> = T & Pagination;

export type APIParams = {
  page?: number;
  sort?: SortSettings;
  defaultSort?: SortSettings[];
  search?: string;
  searchInFilter?: boolean;
  searchCustomKey?: string;
  filters?: ActiveFilters;
  pagination?: number;
  include?: string[] | string;
  custom?: string[] | string;
};

// stuff for calling the API
export type GetDataCallback<T = Item> = (param?: APIParams) => Promise<PaginatedData<T>>;
