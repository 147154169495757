import { App, Plugin } from 'vue';

import { AuthUser } from '@/models/User';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $user: AuthUser | undefined;
  }
}

let global: App<unknown> | undefined = undefined;

export const UserStore: Plugin = {
  install(app) {
    global = app;
  }
};

export function setGlobalUserVariable(user: AuthUser | undefined) {
  if (!global) {
    return;
  }
  global.config.globalProperties.$user = user;
}

export default UserStore;
