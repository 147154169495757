import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import { middlewareLogger } from '@/router/Logger';

import { CURRENT_DOMAIN, isCompanyDomain } from '@/helpers/SubdomainHelper';

import i18n from '@/i18n';
import store from '@/store';

const t = i18n.global.t;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (to: RouteLocation, from: RouteLocation, next: Next) => {
  // console.log("company subdomain middleware: ", subdomain, to);

  if (!isCompanyDomain()) {
    store.dispatch.notification.setError(t('router.errors.notCompanyDomain'));
    middlewareLogger('domain:company: ', CURRENT_DOMAIN);
    next({ name: 'home' });
    return false;
  }
};
