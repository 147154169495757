import { ActionContext } from 'vuex';

import { SelectOption } from '@/composables/Select';

import axios from '@/axios';

import { storeLogger } from '../Logger';
import { State as RootState } from '../state';

type DropdownOption = SelectOption<string>;

// ---------------- ROUTES ---------------- //
const getCountries = '/country';

export type Country = {
  id: number;
  capital: string;
  citizenship: string;
  country_code: string;
  full_name: string;
  iso_2: string;
  name: string;
  calling_code: string;
};

type CountryAPIWrapper = {
  countries: Country[];
};

export type CountryOption = DropdownOption & { iso: string };

// ---------------- STATE ---------------- //
export interface CountryState {
  countries: Country[];
}

const state: CountryState = {
  countries: []
};

// ---------------- MUTATIONS ---------------- //
const mutations = {
  setCountries(state: CountryState, countries: Country[]) {
    storeLogger('setCountries', state, countries);
    state.countries = countries;
  }
};

// ---------------- ACTIONS ---------------- //
type CountryActionContext = ActionContext<CountryState, RootState>;
const actions = {
  async refreshCountries({ commit }: CountryActionContext): Promise<Country[]> {
    const response = await axios.get<CountryAPIWrapper>(getCountries);

    commit('setCountries', response.data.countries);
    return response.data.countries;
  },
  async getCountries({ dispatch, state }: CountryActionContext): Promise<Country[]> {
    if (state.countries.length !== 0) {
      return Promise.resolve(state.countries);
    } else {
      // call api only if no data present
      return dispatch('refreshCountries');
    }
  },
  async getCountryOptions({ dispatch }: CountryActionContext): Promise<CountryOption[]> {
    const nlFirst = (a: { iso: string }, b: { iso: string }) => {
      if (a.iso === 'NL') {
        return -1;
      } else if (b.iso === 'NL') {
        return 1;
      } else {
        return 0;
      }
    };

    return dispatch('getCountries').then((countries: Country[]) => {
      return countries
        .map((country) => ({
          key: country.id.toString(),
          text: country.name,
          iso: country.iso_2
        }))
        .sort(nlFirst);
    });
  }
};

// ---------------- GETTERS ---------------- //
const getters = {};

export default {
  // eslint-disable @typescript-eslint/prefer-as-const
  namespaced: true as const,
  state,
  mutations,
  actions,
  getters
};
