import { createDirectStore } from 'direct-vuex';

import auth from './modules/auth';
import company from './modules/company';
import country from './modules/country';
import notification from './modules/notification';
import notificationMessage from './modules/notificationmessage';
import passwordConfirm from './modules/passwordConfirm';
import table from './modules/table';

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  modules: {
    auth,
    notification,
    company,
    country,
    table,
    notificationMessage,
    passwordConfirm
  }
});

export default store;

export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module 'vuex' {
  interface Store<S> {
    direct: AppStore;
  }
}
