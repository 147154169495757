import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import { isLoggerUsed } from '@/helpers/javascript/LoggerHelper';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (to: RouteLocation, from: RouteLocation, next: Next) => {
  routeLogger(to.name?.toString());
};

const isLoggerActive = isLoggerUsed(import.meta.env.VITE_DEBUG_ROUTER);

function routeLogger(...data: any[]) {
  if (isLoggerActive) {
    console.log('%c Route ', 'background: #222; color: #bada55', ...data);
  }
}
