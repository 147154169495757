import { debounce as _debounce } from 'lodash';
import { App, DirectiveBinding, RendererElement } from 'vue';

export const fullScreenHeightDirective = {
  mounted(el: RendererElement, { value }: DirectiveBinding) {
    if (value === false) {
      // if the passed value is explicitly false, don't attach the listener
      return;
    }

    el.fullscreenHeightListener = _debounce(() => {
      el.style.height = window.innerHeight + 'px';
    }, 200);

    // create event listener
    window.addEventListener('resize', el.fullscreenHeightListener, {
      passive: true
    });

    // set the height once, before the window is changes, to ensure it starts with the right height
    el.fullscreenHeightListener();
  },
  unmounted(el: RendererElement) {
    window.removeEventListener('resize', el.fullscreenHeightListener);
  }
};

export default {
  install: (app: App) => {
    app.directive('full-screen-height', fullScreenHeightDirective);
  }
};
