import { NavigationGuardNext as Next, RouteLocationNormalized as RouteLocation } from 'vue-router';

import { middlewareLogger } from '@/router/Logger';

import { hasMaintenanceRole } from '@/helpers/api/RoleableHelper';

import i18n from '@/i18n';
import store from '@/store';

const t = i18n.global.t;

export default (_to: RouteLocation, _from: RouteLocation, next: Next) => {
  const userRole = store.getters.auth.userRole;

  if (!hasMaintenanceRole(userRole)) {
    store.dispatch.notification.setError(t('router.errors.userRole', { userRole }));
    middlewareLogger('role:owner: ', userRole);
    next({ name: 'dashboard' });
    return false;
  }
};
