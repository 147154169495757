// credit to Dave James Miller https://github.com/davejamesmiller/laravel-breadcrumbs
import { RouteLocationRaw } from 'vue-router';

type GeneratorCallback<Args extends unknown[]> = (trail: BreadcrumbsGenerator, ...params: Args) => void;

type Callbacks = {
  [x: string]: GeneratorCallback<unknown[]>;
};

export type BreadCrumbItem = {
  title: string;
  to?: string | RouteLocationRaw;
  [x: string]: unknown;
};

class BreadcrumbsGenerator {
  protected breadcrumbs: Array<BreadCrumbItem> = [];
  protected callbacks: Callbacks = {};

  public generate(callbacks: Callbacks, name: string, params: Array<unknown>) {
    this.breadcrumbs = [];
    this.callbacks = callbacks;

    this.call(name, params);

    return this.breadcrumbs;
  }

  protected call(name: string, params: Array<unknown>): void {
    const callback = this.callbacks[name];
    if (callback === undefined) {
      throw new Error(`tried calling "${name}" breadcrumb, but it's not declared`);
    }
    callback(this, ...params);
  }

  public parent(name: string, ...params: Array<unknown>): void {
    this.call(name, params);
  }

  public push(title: string, to?: string | RouteLocationRaw, ...data: Array<unknown>): void {
    this.breadcrumbs.push({
      title,
      to,
      ...data
    });
  }
}

class Breadcrumbs {
  generator: BreadcrumbsGenerator;
  callbacks: Callbacks = {};

  constructor(generator: BreadcrumbsGenerator) {
    this.generator = generator;
  }

  for<Args extends unknown[]>(name: string, callback: GeneratorCallback<Args>) {
    if (name in this.callbacks) {
      throw new Error(`duplicate breadcrumb name: ${name}`);
    }
    this.callbacks[name] = callback as GeneratorCallback<unknown[]>;
  }

  exists(name: string): boolean {
    return this.callbacks[name] !== undefined;
  }

  generate(name: string, ...params: Array<unknown>) {
    try {
      return this.generator.generate(this.callbacks, name, params);
    } catch (e) {
      console.warn(e);
      return [];
    }
  }
}

const SINGLETON: Breadcrumbs | undefined = undefined;

function create() {
  if (SINGLETON !== undefined) {
    return SINGLETON;
  }
  const generator = new BreadcrumbsGenerator();
  return new Breadcrumbs(generator);
}

export default { create };
