<template>
  <div class="max-w-sm w-full bg-white shadow-lg rounded-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mb-4">
    <div class="border-l-8 p-4" :class="borderColor">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <svg class="h-6 w-6" :class="color" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" v-html="icon"></svg>
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">
            {{ title }}
          </p>
          <p class="mt-1 text-sm text-gray-500">
            {{ notification.text }}
          </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button
            class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="dismiss"
          >
            <span class="sr-only">
              <i18n-t scope="global" keypath="components.notification.close" />
            </span>
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import i18n from '@/i18n';

import { Notification, NotificationType } from '../notifications/types';

type KeyInStr<T extends string | number> = { [key in T]: string };
type KeyInFn<T extends string | number> = { [key in T]: () => string };

const NOTIFICATION_TTL = 6500;

const props = defineProps<{
  notification: Notification;
}>();

const emits = defineEmits<{
  (e: 'dismiss', value: number): boolean;
}>();

const timeout = ref<string | number | NodeJS.Timeout | undefined>();

const typeTitleMap = ref<KeyInFn<NotificationType>>({
  [NotificationType.ERROR]: () => i18n.global.t('components.notification.title.error'),
  [NotificationType.WARN]: () => i18n.global.t('components.notification.title.warn'),
  [NotificationType.SUCC]: () => i18n.global.t('components.notification.title.success'),
  [NotificationType.INFO]: () => i18n.global.t('components.notification.title.info')
});

const title = computed(() => (props.notification.title ? props.notification.title : typeTitleMap.value[props.notification.type]() || ''));

const typeColorMap = ref<KeyInStr<NotificationType>>({
  [NotificationType.ERROR]: 'text-red-500',
  [NotificationType.WARN]: 'text-yellow-500',
  [NotificationType.SUCC]: 'text-green-500',
  [NotificationType.INFO]: 'text-blue-500'
});

const color = computed(() => typeColorMap.value[props.notification.type] || '');

const typeBorderColorMap = ref<KeyInStr<NotificationType>>({
  [NotificationType.ERROR]: 'border-red-500',
  [NotificationType.WARN]: 'border-yellow-500',
  [NotificationType.SUCC]: 'border-green-500',
  [NotificationType.INFO]: 'border-blue-500'
});

const borderColor = computed(() => typeBorderColorMap.value[props.notification.type] || '');

const typeIconMap = ref<KeyInStr<NotificationType>>({
  [NotificationType.ERROR]:
    '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path>',
  [NotificationType.WARN]:
    '<path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />',
  [NotificationType.SUCC]: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />',
  [NotificationType.INFO]:
    '<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />'
});

const icon = computed(() => typeIconMap.value[props.notification.type] || '');

function dismiss() {
  emits('dismiss', props.notification.id);
  clearTimeout(timeout.value);
}

onMounted(() => (timeout.value = setTimeout(() => dismiss(), NOTIFICATION_TTL)));
</script>
