const removeActiveListenerCallbacks = [] as (() => void)[];

export function addRemoveEventListenerCallback(callback: () => void) {
  removeActiveListenerCallbacks.push(callback);
}

// manual garbage collection of evenListeners
window.addEventListener(
  'unload',
  () => {
    removeActiveListenerCallbacks.forEach((callback) => callback());
  },
  { once: true }
);
