import i18n from '@/i18n';

import { UserRole } from './User';

const $t = i18n.global.t;

export type UserInviteRole =
  | 'manager'
  | 'management_administrator'
  | 'management_employee'
  | 'owner'
  | 'administrator'
  | 'employee'
  | 'service_owner'
  | 'service_employee'
  | 'tenant'
  | 'handyman';

export function renderUserRole(role?: UserInviteRole | UserRole): string {
  const pathPrefix = 'models.invite.role.';
  switch (role) {
    case 'manager':
      return $t(pathPrefix + 'manager');
    case 'management_administrator':
      return $t(pathPrefix + 'management_administrator');
    case 'management_employee':
      return $t(pathPrefix + 'management_employee');
    case 'owner':
      return $t(pathPrefix + 'owner');
    case 'administrator':
      return $t(pathPrefix + 'administrator');
    case 'employee':
      return $t(pathPrefix + 'employee');
    case 'service_owner':
      return $t(pathPrefix + 'service_owner');
    case 'service_employee':
      return $t(pathPrefix + 'service_employee');
    case 'tenant':
      return $t(pathPrefix + 'tenant');
    case 'handyman':
      return $t(pathPrefix + 'handyman');
    case 'maintenance_employee':
      return $t(pathPrefix + 'maintenance_employee');

    default:
      return role ?? 'unknown';
  }
}

export type UserInviteApiData = {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  role: {
    name: UserInviteRole;
  };
};

export type UserInviteApiResponse = {
  invite: UserInviteApiData;
};

export type UserInviteForm = {
  first_name: string;
  last_name: string;
  email: string;
  role_id: string;
};

export class UserInvite {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  role: UserInviteRole;

  get name(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  constructor(params: UserInviteApiData) {
    this.email = params.email;
    // eslint-disable @typescript-eslint/naming-convention
    this.first_name = params.first_name;
    this.id = params.id;
    // eslint-disable @typescript-eslint/naming-convention
    this.last_name = params.last_name;
    this.role = params.role.name;
  }
}
