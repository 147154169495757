<template>
  <slot v-if="!isLoading" />
  <template v-else>
    <div v-full-screen-height class="bg-gray-100">
      <div class="h-full flex flex-wrap content-center justify-center">
        <div class="flex flex-col justify-center items-center">
          <div class="mb-6">
            <LogoIcon />
          </div>
          <div class="loading-bar"></div>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import LogoIcon from '@/components/static/LogoIcon.vue';

export default defineComponent({
  name: 'FullPageLoader',
  props: {
    isLoading: Boolean
  },
  components: {
    LogoIcon
  }
});
</script>
