import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const idProp =
  (idName = 'id') =>
  (route: RouteLocationNormalized) => {
    // this function casts the id param to int
    const id = +route.params[idName];
    // console.log("id prop parser, ", id);
    return { ...route.params, [idName]: id };
  };

const routes: Array<RouteRecordRaw> = [
  {
    path: 'dashboard',
    name: 'tenant.dashboard',
    component: () => import('@/views/tenant/Dashboard.vue')
  },
  {
    path: 'reports',
    name: 'tenant.report.index',
    component: () => import('@/views/tenant/report/Index.vue')
  },
  {
    path: 'report/:id',
    name: 'tenant.report.show',
    component: () => import('@/views/tenant/report/Show.vue'),
    props: idProp()
  },
  {
    path: 'messages',
    name: 'tenant.message.index',
    component: () => import('@/views/common/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => {
      return { ...route.params, isTenant: true };
    }
  },
  {
    path: 'messages/unread',
    name: 'tenant.message.unread',
    component: () => import('@/views/common/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => {
      return { ...route.params, isTenant: true };
    }
  },
  {
    path: 'messages/archive',
    name: 'tenant.message.archive',
    component: () => import('@/views/common/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => {
      return { ...route.params, isTenant: true };
    }
  },
  {
    path: 'announcements',
    name: 'tenant.announcement.index',
    component: () => import('@/views/tenant/announcement/Index.vue')
  },
  {
    path: 'invoices',
    name: 'tenant.invoice.index',
    component: () => import('@/views/tenant/invoice/Index.vue')
  },
  {
    path: 'invoice/:invoiceUuid',
    name: 'tenant.invoice.show',
    component: () => import('@/views/tenant/invoice/Show.vue'),
    props: true
  },
  {
    path: 'agreements',
    name: 'tenant.agreement.index',
    component: () => import('@/views/tenant/agreement/Index.vue')
  },
  {
    path: 'agreement/:agreementId',
    name: 'tenant.agreement.show',
    component: () => import('@/views/tenant/agreement/Show.vue'),
    props: idProp('agreementId')
  },
  {
    path: 'account',
    name: 'tenant.account',
    component: () => import('@/views/common/Account.vue')
  }
];

export default routes;
