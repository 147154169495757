import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const idProp =
  (idName = 'id') =>
  (route: RouteLocationNormalized) => {
    // this function casts the id param to int
    const id = +route.params[idName];
    // console.log("id prop parser, ", id);
    return { ...route.params, [idName]: id };
  };

const routes: Array<RouteRecordRaw> = [
  {
    path: 'dashboard',
    name: 'service.dashboard',
    component: () => import('@/views/service/Dashboard.vue')
  },
  {
    path: 'notifications',
    name: 'service.notifications',
    component: () => import('@/views/common/Notifications.vue')
  },
  {
    path: 'account',
    name: 'service.account',
    component: () => import('@/views/common/Account.vue')
  },
  {
    path: 'assignments',
    name: 'service.assignment.index',
    component: () => import('@/views/service/assignment/Index.vue')
  },
  {
    path: 'assignment/:id',
    name: 'service.assignment.show',
    component: () => import('@/views/service/assignment/Show.vue'),
    props: idProp()
  },

  // Service partijen
  {
    path: 'service',
    name: 'service.assignment.service',
    component: () => import('@/views/service/service/Connected.vue')
  },
  {
    path: 'service/:id',
    name: 'service.assignment.service.show',
    component: () => import('@/views/service/service/Show.vue'),
    props: idProp()
  },
  {
    path: 'service/invited',
    name: 'service.assignment.service.invited',
    component: () => import('@/views/service/service/Invited.vue')
  },

  {
    path: 'calendar',
    name: 'service.calendar',
    component: () => import('@/views/service/Calendar.vue')
  },
  {
    path: 'team',
    component: () => import('@/views/service/team/Wrapper.vue'),
    children: [
      {
        path: '',
        name: 'service.team.index',
        component: () => import('@/views/service/team/Index.vue')
      },
      {
        path: 'invites',
        name: 'service.team.invite.index',
        component: () => import('@/views/service/team/invite/Index.vue')
      }
    ]
  },

  {
    path: 'clients',
    component: () => import('@/views/service/client/Wrapper.vue'),
    children: [
      {
        path: '',
        name: 'service.client.index',
        component: () => import('@/views/service/client/company/Index.vue')
      },
      {
        path: 'invites',
        name: 'service.client.invite.index',
        component: () => import('@/views/service/client/invite/Index.vue')
      },
      {
        path: 'serviceClient',
        name: 'service.client.serviceClient.index',
        component: () => import('@/views/service/client/serviceClient/Index.vue')
      }
    ]
  },
  {
    path: 'client/serviceClient/:id',
    name: 'service.client.serviceClient.show',
    component: () => import('@/views/service/client/serviceClient/Show.vue'),
    props: idProp()
  },
  {
    path: 'client/company/:id',
    name: 'service.client.company.show',
    component: () => import('@/views/service/client/company/Show.vue'),
    props: idProp()
  },

  {
    path: 'locations',
    name: 'service.location.index',
    component: () => import('@/views/service/location/Index.vue')
  },
  {
    path: 'location/:id',
    name: 'service.location.show',
    component: () => import('@/views/service/location/Show.vue'),
    props: idProp()
  },

  {
    path: 'map',
    name: 'service.map',
    component: () => import('@/views/service/Map.vue'),
    meta: { fullscreen: true }
  },
  {
    path: 'company/switch',
    name: 'service.switch',
    component: () => import('@/views/CompanySwitcher.vue')
  },
  {
    path: 'settings',
    component: () => import('@/views/service/settings/Wrapper.vue'),
    children: [
      {
        path: '',
        name: 'service.settings',
        component: () => import('@/views/service/settings/General.vue')
      },
      {
        path: 'flows',
        name: 'service.settings.repairflows',
        component: () => import('@/views/company/settings/repairflow/RepairFlows.vue')
      },
      {
        path: 'branding',
        name: 'service.settings.branding',
        component: () => import('@/views/service/settings/Branding.vue')
      },
      {
        path: 'index',
        name: 'settings.subscription.index',
        props: idProp(),
        component: () => import('@/views/service/settings/Subscription/Index.vue')
      }
    ]
  },
  {
    path: 'settings/flows/:id',
    name: 'service.settings.repairflows.show',
    component: () => import('@/views/company/settings/repairflow/RepairFlowView.vue'),
    props: idProp()
  }
];

export default routes;
