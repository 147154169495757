import { ActionContext } from 'vuex';

import { domainBase, post } from '@/helpers/api';

import { storeLogger } from '../Logger';
import { State as RootState } from '../state';

export type ConfirmPasswordForm = {
  password: string;
};

export interface PasswordConfirmState {
  showPasswordConfirm: boolean;
  hasRecentlyConfirmed: boolean;
}

type PasswordConfirmContext = ActionContext<PasswordConfirmState, RootState>;

const state: PasswordConfirmState = {
  showPasswordConfirm: false,
  hasRecentlyConfirmed: false
};

const mutations = {
  handle423(state: PasswordConfirmState): void {
    storeLogger('handle423', state);
    state.showPasswordConfirm = true;
    state.hasRecentlyConfirmed = false;
  },
  handleConfirmed(state: PasswordConfirmState): void {
    storeLogger('handleConfirmed', state);
    state.showPasswordConfirm = false;
    state.hasRecentlyConfirmed = true;
  },
  setShowPasswordConfirm(state: PasswordConfirmState, showPasswordConfirm: boolean): void {
    storeLogger('setShowPasswordConfirm', state, showPasswordConfirm);
    state.showPasswordConfirm = showPasswordConfirm;
  }
};

const actions = {
  async confirmPassword({ commit }: PasswordConfirmContext, form: ConfirmPasswordForm): Promise<null> {
    const url = domainBase('/password/confirm');

    return post(url, '', form).then(() => {
      commit('handleConfirmed');
      return null;
    });
  }
};

const getters = {
  //
};

export default {
  // eslint-disable @typescript-eslint/no-object-literal-type-assertion
  namespaced: true as const,
  state,
  mutations,
  actions,
  getters
};
