// NOTE: the auth cookie has two functions:
// - check if the user has been authenticated before, and assume this status until an 403 is received.
// - hold the user role (company, management or tenant), and assume this role until the user is properly loaded.
import axiosGlobal from 'axios';
import { ActionContext } from 'vuex';

import { Roleable, RoleableApiData } from '@/models/Rolables';
import LoginForm from '@/models/forms/Login';

import { isCompanyDomain, isManagementDomain, isServiceDomain } from '@/helpers/SubdomainHelper';
import { convertToFormData } from '@/helpers/api';
import { getActiveRoleables, redirectToDomainSwitcher } from '@/helpers/api/RoleableHelper';

import axios from '@/axios';
import { setGlobalUserVariable } from '@/globalComponents/UserStore';
import { loadLanguageAsync } from '@/i18n';

import { AuthUser, UserApiData, UserRole } from '../../models/User';
import { storeLogger } from '../Logger';
import { State as RootState } from '../state';

type TwoFactorAuthResponse = { two_factor: true };

function isTwoFactorResponse(response: UserWrapper | TwoFactorAuthResponse): response is TwoFactorAuthResponse {
  return typeof (response as TwoFactorAuthResponse).two_factor === 'boolean';
}

const getCsrfTokenRoute = 'sanctum/csrf-cookie';

export type LoginType = 'company' | 'service' | 'management';
export interface AuthState {
  user: undefined | AuthUser;
  isLoading: boolean;
  unsure: boolean;
}

export type UserWrapper = {
  user: UserApiData;
  roleable?: RoleableApiData[];
};
type AuthContext = ActionContext<AuthState, RootState>;

const state: AuthState = {
  user: undefined,
  isLoading: true,
  unsure: false
};

function convertToUser(data: UserWrapper): AuthUser {
  if (!data.roleable) {
    throw new Error('Permissions not send by backend');
  }
  const roleables = data.roleable.map((data) => new Roleable(data));

  const activeRoleables = getActiveRoleables(roleables);

  if (activeRoleables.length === 0) {
    redirectToDomainSwitcher();
    throw new Error('User does not have access to this subdomain, redirecting');
  }

  return new AuthUser(data.user, roleables, activeRoleables);
}

export type UpdateAccountForm = {
  first_name: string;
  last_name: string;
  email: string;
  locale: string;
  avatar: File | Blob | null | undefined;
};

export type UpdatePasswordForm = {
  old_password: string;
  password: string;
  password_confirmation: string;
};

function resolveEnvironmentType(): LoginType {
  if (isServiceDomain()) {
    return 'service';
  } else if (isManagementDomain()) {
    return 'management';
  } else if (isCompanyDomain()) {
    return 'company';
  } else {
    throw new Error('Unknown environment');
  }
}

const mutations = {
  setUser(state: AuthState, user: AuthUser): void {
    storeLogger('setUser', state, user);
    state.user = user;

    setGlobalUserVariable(user);
    loadLanguageAsync(user.locale);
  },
  removeUser(state: AuthState): void {
    storeLogger('removeUser', state);

    state.user = undefined;
    state.unsure = false;
  },
  setLoading(state: AuthState, loading: boolean): void {
    storeLogger('setLoading', state, loading);
    state.isLoading = loading;
  },
  setUnsure(state: AuthState, unsure: boolean): void {
    storeLogger('setUnsure', state, unsure);
    state.unsure = unsure;
  },
  set2FAStatus(state: AuthState, is2FAEnabled: boolean) {
    storeLogger('set2FAStatus', state, is2FAEnabled);
    if (!state.user) {
      throw new Error('Could not set 2FA status on absent user');
    }

    state.user.uses_2fa = is2FAEnabled;
  },
  set2FAConfirm(state: AuthState, is2FAConfirmed: boolean) {
    storeLogger('set2FAConfirm', state, is2FAConfirmed);
    if (!state.user) {
      throw new Error('Could not set 2FA confirm on absent user');
    }

    state.user.two_factor_confirmed = is2FAConfirmed;
  }
};

function handleUserRequestLogic({ commit, dispatch }: AuthContext, data: UserWrapper): AuthUser {
  const user = convertToUser(data);
  commit('setUser', user);
  if (!isManagementDomain()) dispatch('notificationMessage/getNotifications', undefined, { root: true });
  return user;
}

const actions = {
  async setCsrfCookie(): Promise<void> {
    await axios.get(getCsrfTokenRoute);
  },

  async login(context: AuthContext, form: LoginForm): Promise<AuthUser> {
    const url = `${resolveEnvironmentType()}/login`;

    const response = await axios.post<UserWrapper | TwoFactorAuthResponse>(url, form);

    if (isTwoFactorResponse(response.data)) {
      throw response.data;
    }

    return handleUserRequestLogic(context, response.data);
  },

  async getUser(context: AuthContext): Promise<AuthUser | null> {
    try {
      context.commit('setLoading', true);
      const url = `${resolveEnvironmentType()}/user`;

      const userResponse = await axios.get<UserWrapper>(url).finally(() => context.commit('setLoading', false));

      if (!userResponse) {
        context.commit('removeUser');
        return null;
      }

      return handleUserRequestLogic(context, userResponse.data);
    } catch (e) {
      if (axiosGlobal.isAxiosError(e) && e.response?.status === 401) {
        context.commit('removeUser');
        return null;
      } else {
        throw e;
      }
    }
  },

  async updateUser(
    { commit, dispatch }: AuthContext,
    form:
      | UpdateAccountForm
      | UpdatePasswordForm
      | {
          dark_mode: boolean;
        }
  ): Promise<AuthUser | null> {
    const url = `${resolveEnvironmentType()}/user`;

    const formData = convertToFormData(form);
    formData.append('_method', 'patch');

    return axios
      .post<UserWrapper>(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => response.data)
      .then(convertToUser)
      .then((user) => {
        commit('setUser', user);
        return user;
      });
  },

  async logout({ commit }: AuthContext): Promise<void> {
    const url = `${resolveEnvironmentType()}/logout`;

    await axios.post(url);
    commit('removeUser');
  }
};

const getters = {
  isAuthenticated: (state: AuthState): boolean => state.user !== undefined,
  userRole: ({ user }: AuthState): UserRole | undefined => user?.role
};

export default {
  // eslint-disable @typescript-eslint/no-object-literal-type-assertion
  namespaced: true as const,
  state,
  mutations,
  actions,
  getters
};
