<template>
  <FullPageLoader :is-loading="!companyNull">
    <div
      v-full-screen-height
      class="bg-gray-50 bg-cover bg-no-repeat bg-center flex flex-col justify-center px-6 sm:px-6 lg:px-8"
      :style="{ backgroundImage: 'url(' + company.cover + ')' }"
    >
      <div class="mt-8 sm:mx-auto sm:w-full md:max-w-lg">
        <div class="bg-white py-10 px-6 shadow border border-gray-200 rounded-md sm:px-16 sm:py-16">
          <div class="flex justify-center">
            <div
              class="w-40 h-20 md:w-48 md:h-28 bg-contain bg-no-repeat bg-top"
              :style="{
                backgroundImage: 'url(' + company.logo + ')'
              }"
              alt=""
            />
          </div>
          <h2 class="heading-2 text-center mt-2 dark:text-gray-800">
            <i18n-t scope="global" keypath="views.welcome.title">
              <template #company>
                <br />
                {{ company.name_display }}
              </template>
            </i18n-t>
          </h2>
          <p v-if="company.about" class="mt-4 text-center text-sm md:text-base text-gray-600 px-4 md:px-0">
            {{ company.about }}
          </p>
          <div class="mt-10">
            <VastgoedButton :text="$t('views.welcome.report')" :to="{ name: 'tenant.repair.explanation' }" :color="ButtonColor.DARKBLUE" :size="ButtonSize.LGF" />
          </div>
          <div class="mt-4">
            <VastgoedButton :text="$t('views.welcome.login')" :color="ButtonColor.BLUE" :size="ButtonSize.LGF" :to="{ name: 'login' }" icon="lock" />
          </div>
        </div>
        <div class="flex justify-center bottom-0 mx-auto items-center mt-10">
          <LogoIcon />
        </div>
      </div>
    </div>
  </FullPageLoader>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// components
import FullPageLoader from '@/components/FullPageLoader.vue';
import LogoIcon from '@/components/static/LogoIcon.vue';

import store from '@/store';

export default defineComponent({
  name: 'Welcome',
  components: {
    LogoIcon,
    FullPageLoader
  },
  computed: {
    companyNull() {
      return store.state.company.publicCompany;
    },
    company() {
      return store.state.company.publicCompany!;
    }
  }
});
</script>
