import { nextTick, ref } from 'vue';

import { PublicCompanyData } from '@/store/modules/company';

import Company from '@/models/Company';

import { baseUrl, convertToFormData, destroy, get, patch } from '@/helpers/api';
import { errorHandler } from '@/helpers/api/ErrorHandler';
import { openUrl } from '@/helpers/javascript/OpenURL';

import store from '@/store';

type BizcuitCreateResponse = {
  authorization: string;
  signup: string;
};

export const showReauthorizeModal = ref<boolean>(false);

export async function createBizcuit(): Promise<BizcuitCreateResponse> {
  const url = baseUrl('/bizcuit/auth');
  const response = await get<{ data: BizcuitCreateResponse }>(url, 'data');
  if (!response) throw new Error('Could not reach bizcuit');
  return response;
}

export async function updateBizcuitbank(company: Company | PublicCompanyData, active = false) {
  const uuid = company?.bizcuit?.bizcuit_bank.uuid;
  const url = baseUrl(`/bizcuit/bank/${uuid}`);

  return await patch(
    url,
    '',
    convertToFormData({
      id: uuid,
      active: active
    })
  );
}

export async function destroyBizcuitConnection(company: Company | PublicCompanyData | null) {
  if (company) await updateBizcuitbank(company);

  await nextTick();

  const url = baseUrl('/bizcuit/auth');

  try {
    await destroy(url);
  } catch (error) {
    errorHandler(error);
  }
}

export async function authorizeBizcuit(company?: Company | PublicCompanyData | null, reauthorize: boolean = false) {
  if (reauthorize && company) await updateBizcuitbank(company); // a foreign key constraint fails

  try {
    const response = await createBizcuit();
    return openUrl(response?.authorization, false);
  } catch (err) {
    store.dispatch.notification.setError(err.response?.data.message ?? err.message);
  }
}
