<template>
  <div class="flex justify-start items-end mb-2 sm:mb-0">
    <h1 v-if="title" class="capitalize text-lg md:text-xl xl:text-2xl font-bold text-gray-700 dark:text-gray-500 truncate ...">
      {{ title }}
    </h1>
    <div v-if="title && subTitle" class="flex flex-col items-end">
      <span class="text-xl xl:text-2xl mx-1 font-bold text-gray-500">&centerdot;</span>
    </div>
    <h3 v-if="subTitle" class="text-lg md:text-xl xl:text-2xl font-medium text-gray-400 whitespace-nowrap truncate">
      {{ subTitle }}
    </h3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageHeader',
  props: {
    title: {
      default: '',
      type: String
    },
    subTitle: {
      default: '',
      type: String
    }
  }
});
</script>
