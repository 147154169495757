import { Roleable, RoleablesApiResponse } from '@/models/Rolables';
import { UserRole } from '@/models/User';

import axios from '@/axios';

import { isManagementDomain, isServiceDomain } from '../SubdomainHelper';

const MAIN_DOMAIN = import.meta.env.VITE_MAIN_DOMAIN;

const roleablesRoute = 'company/company/switch';

export async function getRoleables(): Promise<Roleable[]> {
  const response = await axios.get<RoleablesApiResponse>(roleablesRoute);
  return response.data.roleables.map((data) => new Roleable(data));
}

function hasRoleableAccessToDomain(roleable: Roleable, subdomain: string) {
  if (isManagementDomain(subdomain) && roleable.isManagement()) {
    return true;
  }
  if (isServiceDomain(subdomain) && roleable.isService()) {
    return true;
  }

  return roleable.domain === subdomain;
}

export function getActiveRoleables(roleables: Roleable[]): Roleable[] {
  const subdomain = window.location.hostname.split('.')[0];

  return roleables.filter((roleable) => hasRoleableAccessToDomain(roleable, subdomain));
}

export function redirectToDomainSwitcher(): void {
  const url = `${MAIN_DOMAIN}/company/switch`;
  window.location.replace(url);
}

const roleNameMap = new Map<number, UserRole>([
  [1, 'manager'],
  [2, 'owner'],
  [3, 'employee'],
  [4, 'service_owner'],
  [5, 'service_employee'],
  [6, 'tenant'],
  [7, 'handyman'],
  [8, 'administrator'],
  [9, 'management_administrator'],
  [10, 'management_employee'],
  [11, 'service_mechanic'],
  [12, 'maintenance_employee']
]);

export function roleIdToName(roleId: number): UserRole {
  const role = roleNameMap.get(roleId);

  if (!role) {
    throw new Error(`Unknown rol id: ${roleId}. Could not set permission rights on user object`);
  }

  return role;
}

// any role not included in one of the three definitions below will crash the application, HARD

export function hasCompanyRole(role?: UserRole): boolean {
  return role === 'owner' || role === 'administrator' || role === 'employee' || role === 'handyman' || hasManagementRole(role);
}

export function hasManagementRole(role?: UserRole): boolean {
  return role === 'manager' || role === 'management_administrator' || role === 'management_employee';
}

export function hasTenantRole(role?: UserRole): boolean {
  return role === 'tenant';
}

export function hasServiceCompanyRole(role?: UserRole): boolean {
  return role === 'service_owner' || role === 'service_employee' || role === 'service_mechanic';
}

export function hasMaintenanceRole(role?: UserRole): boolean {
  return role === 'maintenance_employee';
}
